<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'inventoryDetails', action: null })" size="lg" extraLarge fullHeight hideFooter hideHeaderExtra>
    <template v-slot:modal-title>
      <b-row>
        <b-col lg="3" md="4" cols="6">
          <b-row>
            <b-col class="d-flex align-items-center" :class="{ warning: inventory && inventory.archived }" :title="isLocked ? translations.txtInventoriesLockedDescription : ''">
              <h5 class="modal-title me-2 float-start" style="">{{ modalTitle }}</h5>
              <icon v-if="isLocked" name="wisk-lock" class="text-primary" :scale="1" />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="inventory && inventory.id">
              <small class="" @click="setDescription" v-if="inventory && inventory.description"> {{ inventory.description }} </small>
              <small v-else class="font-italic" @click="setDescription"> {{ translations.txtGenericAddDescription }} </small>
              <b-button variant="link" @click="setDescription" class="py-0">
                <icon name="wisk-edit" class="text-primary" :scale="0.7" />
              </b-button>
            </b-col>
          </b-row>

        </b-col>
        <b-col lg="3" md="3" cols="6">
          <b-form-radio-group size="sm" class="float-end" buttons v-model="viewMode" name="viewMode" button-variant="outline-primary">
            <b-form-radio value="item"> {{ translations.txtGenericByItem }} </b-form-radio>
            <b-form-radio value="item_location"> {{ translations.txtGenericByLocation }} </b-form-radio>
            <b-form-radio value="timeline"> {{ translations.txtGenericTimeline }} </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col lg="4" md="3" cols="6">
          <multiselect v-if="(viewMode === 'item_location') && filterLocations && filterLocations.length && filterLocations[0]"
            :placeholder="translations.txtInventoriesFilterByLocation"
            track-by="id"
            class="float-start"
            label="title"
            :options="filterLocations"
            multiple
            :searchable="false"
            :close-on-select="true"
            style="width: 100%;"
            :max-height="450"
            v-model="selectedLocations"
            :selectLabel="translations.txtGenericClickSelect"
            :deselectLabel="translations.txtGenericClickDeselect" />
        </b-col>
        <b-col lg="2" md="2" cols="6" style="padding-right: 2rem !important;">
          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" class="float-end" />
        </b-col>
      </b-row>
    </template>
    <b-row no-gutters>
      <b-col>
        <b-alert v-if="inventoryOngoing && inventoryDurationUnusual" variant="warning" show class="mb-2 text-center">
          {{ translations.txtInventoryUnusualDuration }}
          <infoTooltip helpKey="58cdcbd5-9ccb-401b-9bed-5fa5b2bd6130" />
        </b-alert>
        <b-alert v-if="!isLocked && inventoryData?.current?.scheduled_locking_at" variant="warning" show class="mb-2 text-center">
          {{ translations.translate('tplInventoryDetailsScheduledLock', { '{a}': formatDate(inventoryData?.current?.scheduled_locking_at) }) }}
        </b-alert>
      </b-col>
    </b-row>

    <timeline v-if="viewMode === 'timeline' && !loading" parentGridName="InventoryEditTimeline" :filters="[{ type: 'inventory', id: editAction.id }]" showSearch />

    <wiskItemsGrid v-if="viewMode !== 'timeline' && columns && gridOptions" class="p-0" gridAutoHeight :columns="columns" :items="visibleItems" :header="{}" :options="gridOptions" trackBy="combined_id" :key="gridKey" @searchQueryClear="itemIdFilter = null" :additionalHeaderCols="7" :additionalHeaderColsMobile="10" :itemIdFilter="itemIdFilter" :searchLabel="translations.txtGenericTypeToSearch" :customFilter="customFilter" :excel="{ fileName: 'InventoryItems' }" :parentGridName="`InventoryItems_${viewMode}`" :defaultFilter="{ predicate: item => !!item, name: 'all', label: 'All' }" :loading="loading">

      <template v-slot:additional-controls>
        <div class="align-self-start" style="min-width: 200px">
          <dateRangeSelector v-if="allLocationsCompleted && inventory.approved_by" :label="translations.txtGenericInventoryDuration" infoTooltipKey="4194999b-cb33-4875-82a1-d99b8e6f960e" :shortcuts="false"
            @update:modelValue="updateInventoryDuration" :missingEndLabel="translations.txtInventoriesFinishedAtNone" :disabled="isLocked" :modelValue="inventoryDuration" :validator="inventoryDurationValidatorWithText">
            <template v-slot:prepend>
              <icon v-if="inventoryDurationUnusual" name="wisk-warning" class="text-danger float-start me-1 mt-1" style="margin-left: -5px" :scale="0.7" v-tooltip="translations.confirmInventoryDurationUnusualTitle" />
            </template>
          </dateRangeSelector>

          <wiskInput v-else :label="translations.txtGenericStartDate" class="float-start" :modelValue="inventoryDuration[0]" @operation="updateInventoryStartDate" operation="start_date" inputType="datepicker" required />
        </div>
        <div class="align-self-start" style="min-width: 200px">
          <dateRangeSelector :label="translations.txtInventoriesFilterByCreatedDate" infoTooltipKey="f212f149-9d09-4b57-90d8-09d26580cd0a" v-model="selectedDateRange" clearable />
        </div>
        <div style="flex-basis: 100%; height: 0" />
        <div class="align-self-start" style="min-width: 200px">
          <b-button size="sm" class="align-self-start" variant="link" @click="getInventory"> {{ translations.txtWiskGridRefreshData }} </b-button>
        </div>
        <div class="align-self-start" style="min-width: 200px">
          <wiskInput infoTooltipKey="0fa997e1-33cd-4beb-a325-f9cefd853d9e" class="m-0" readonly showPlainText inputType="number" :decimals="2" :prefix="currency" :modelValue="total"
            :label="translations.txtGenericTotal" defaultIfEmpty="0" />
        </div>
        <div class="align-self-start" style="min-width: 200px">
          <wiskInput infoTooltipKey="fff13893-b14e-4d86-84c9-49dccb7d3407" class="m-0" :label="translations.txtConsumptionExpandBatches" v-model="expandBatches" inputType="checkbox" />
        </div>
      </template>
    </wiskItemsGrid>

    <customFields v-if="inventoryData?.current" target="inventory" :item="inventoryData.current" @operation="updateInventory" class="mb-0" mdCol="3" />

    <inventoryItemQuantity @hide="onAddItemHide" @refresh="refreshFromDetails" :inventory="inventoryData?.current" v-if="inventoryData?.current" :item="selectedItem" @itemFieldChange="onItemFieldChange" :forceVisible="addItemVisible" :locationId="locationId" :editable="!isLocked" />

    <confirm ref="confirmDialog" autofocus />
    <confirm ref="inventoryDurationConfirmDialog">
      <a href="https://help.wisk.ai/en/articles/5986898" target="_blank">{{ translations.txtGenericLearnMore }}</a>
    </confirm>
    <!-- <wiskLoading :loading="loading" /> -->
  </wiskModal>
</template>

<script>
import { toRefs } from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import multiselect from 'vue-multiselect'
import { DateTime } from 'luxon'
import api from '@/api'
import { liveQueryHandler } from '@/modules/db'
import { formatDate, compareNumbers } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import dateRangeSelector from '@/components/common/DateRangeSelector'
import timeline from '@/components/timeline/Timeline'
import customFields from '@/components/customFields/CustomFieldsRender'
import inventoryItemQuantity from '@/components/inventories/InventoryItemQuantity'
import { getItemsGridColumns } from '@/components/inventories/gridOptions'

const newInventory = { id: 0 }

export default {
  name: 'InventoryDetails',
  setup(props) {
    const { editAction } = toRefs(props)
    return {
      inventoryEntriesImmutable: liveQueryHandler({ type: 'inventory_location_measurement', key: 'inventory_id', payload: editAction.value.id })
    }
  },
  components: { wiskItemsGrid, dateRangeSelector, multiselect, inventoryItemQuantity, timeline, customFields },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      inventory: null,
      inventoryData: null,
      loading: false,
      columns: null,
      expandBatches: false,
      gridKey: 1,
      itemIdFilter: null,
      addItemVisible: false,
      selectedLocations: [],
      locationId: null,
      selectedItemId: null,
      selectedItem: null,
      items: [],
      filterLocations: [],
      viewMode: 'item',
      inventoryDuration: [],
      formatDate,
      selectedDateRange: [],
      intervalType: 'date_range',
      inventoryReportItemXlsLoading: false,
      inventoryReportLocationXlsLoading: false,
      itemsByLocationXlsLoading: false
    }
  },
  computed: {
    ...mapState(['recentInventoriesById', 'user', 'itemVariantPricesById', 'translations', 'bottlesById', 'locations', 'firestoreInitialLoadComplete', 'inventories', 'itemVariantsById', 'currentPermissionsByType', 'currentPlanFeaturesByType', 'locationsById', 'customFieldsByTarget', 'inventoryLocationsByInventoryId', 'subrecipesByInventoryItemId']),
    ...mapGetters(['venue', 'currency']),
    inventoryDurationUnusual() {
      if (this.inventory) {
        return this.inventoryDurationValidator([this.inventory.started_at, this.inventory.finished_at])
      }
      return false
    },
    inventoryOngoing() {
      return this.inventory && (!this.allLocationsCompleted || !this.inventory.approved_by || !this.inventory.finished_at)
    },
    isLocked() {
      return this.inventory?.is_locked
    },
    last10LockedInventories() {
      return this.inventories.filter(i => i.is_locked).slice(0, 10)
    },
    canUnlock() {
      return this.currentPermissionsByType.inventory_unlock && this.isLocked && this.inventory?.id && this.last10LockedInventories.find(i => i?.id === this.inventory.id)
     },
    lockedInventoriesAboveThis() {
      return this.last10LockedInventories.filter(i => i.date > new Date(this.inventory.started_at))
    },
    inventoryLiveWatcherComputed() {
      return this.recentInventoriesById[this.inventory?.id]
    },
    pageActions() {
      let actions = [
        { key: 10, title: this.translations.txtGenericAddItem, action: this.setAddBottleVisible, variant: 'primary', hide: this.isLocked, icon: 'wisk-plus' },
        { key: 11, title: this.translations.txtCustomFieldsView, action: this.openCustomFields },
        { key: 12, title: this.translations.txtCustomFieldAdd, action: this.openAddCustomField },
        { key: 20, title: this.translations.txtInventoriesDownloadInventoryReport, action: this.getInventoryReportSpreadsheet, loading: this.inventoryReportItemXlsLoading },
        { key: 21, title: this.translations.txtInventoriesDownloadInventoryReportByLocation, action: this.getInventoryReportByLocationSpreadsheet, loading: this.inventoryReportLocationXlsLoading },
        { key: 30, title: this.translations.txtInventoriesDownloadSpreadsheetByLocation, action: this.getLocationsSpreadsheet, loading: this.itemsByLocationXlsLoading },
        { key: 40, title: this.translations.txtInventoryReportSummaryPDF, action: this.emailInventoryReportSummaryPdf },
        { key: 41, title: this.translations.txtInventoryBarcodesPDF, action: this.emailInventoryBarcodesPdf }
      ]

      let consumptionEnabled = get(this.currentPlanFeaturesByType, 'consumption.enabled', false),
        varinaceEnabled = get(this.currentPlanFeaturesByType, 'pos_integration.enabled', false),
        title = consumptionEnabled && varinaceEnabled ? this.translations.txtInventoriesEmailReports : this.translations.txtUsersEmailReports

      actions.push({ key: 45, title, action: this.emailReports })

      if (this.inventory?.id && this.currentPermissionsByType.inventory_manage && this.allLocationsCompleted && this.inventory.approved_by) {
        let tooltip = this.isLocked && !this.canUnlock ? this.translations.txtInventoriesUnlockDisabledTooltip : ''
        actions.push(
          { key: 50, title: this.translations.txtInventoriesUnlock, icon: 'wisk-lock-open', variant: 'primary', action: this.unlock, hide: !this.isLocked, disabled: !this.canUnlock, tooltip },
          { key: 55, title: this.translations.txtInventoriesLock, icon: 'wisk-lock', variant: 'danger', action: this.lock, hide: this.isLocked }
        )
      }

      if (this.inventory?.id && !this.isLocked && this.currentPermissionsByType.inventory_archive) {
        actions.push(
          { key: 6, isDivider: true },
          { key: 7, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.inventory.archived, action: this.toggleArchive },
          { key: 8, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.inventory.archived, action: this.toggleArchive }
        )
      }


      return actions
    },
    customFilter() {
      if (this.viewMode === 'item_location' && this.selectedLocations?.length) {
        return {
          predicate: item => {
            let id = item.inventoryItem?.location?.id,
              locations = this.selectedLocations || [],
              found = locations.filter(location => location?.id === id)
            return !!found.length
          },
          name: 'location',
          label: 'location'
        }
      }
      return null
    },
    modalTitle() {
      if (this.inventory?.id) {
        return this.translations.translate(this.inventoryOngoing ? 'tplInventoriesEditTitleOngoing' : 'tplInventoriesEditTitle', {
          '{a}': (this.inventory.user && this.inventory.user.name) || ''
        })
      }
      return ''
    },
    total() {
      return get(this.inventory, 'stats.stats.dollars', 0)
    },
    gridOptions() {
      return {
        groupDefaultExpanded: -1,
        suppressAggFuncInHeader: true,
        getRowHeight: this.getRowHeightInner,
        autoGroupColumnDef: {
          cellClass: params => (params.node.group && ['wisk-full-width-cell', 'wisk-group-actions-wrapper', 'header']) || [''],
          cellRendererParams: {
            getSubtractFromAllChildrenCount: this.getSubtractFromAllChildrenCount
          }
        }
      }
    },
    visibleItems() {
      if (this.selectedDateRange.length) {
        const [fromDate, toDate] = this.selectedDateRange,
          fromDateTimestamp = fromDate?.getTime?.(),
          toDateTimestamp = toDate?.getTime?.()

        return this.items.filter(item => item.added_at_timestamp >= fromDateTimestamp && item.added_at_timestamp <= toDateTimestamp)
      }
      return this.items
    },
    allLocationsCompleted() {
      if (this.inventoryLocationsByInventoryId && this.inventory) {
        const inventoryLocations = this.inventoryLocationsByInventoryId[this.inventory.id] || [],
          temp = inventoryLocations.length === inventoryLocations.filter(l => !!l.finished_at).length
        return temp
      }
      return false
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setInventory', 'updateBottle']),
    inventoryDurationValidatorWithText(duration) {
      const isNotValid = this.inventoryDurationValidator(duration)
        if (duration[0] && duration[1]) {
          return isNotValid && this.translations.confirmInventoryDurationUnusualTitle
        }
        if (duration[0]) {
          return isNotValid && this.translations.txtInventoryUnusualDuration
        }
        return false
    },
    inventoryDurationValidator(duration) {
      const start = duration[0]?.getTime ? DateTime.fromJSDate(duration[0]) : DateTime.fromISO(duration[0]),
        end = duration[1]?.getTime ? DateTime.fromJSDate(duration[1]) : DateTime.fromISO(duration[1])
      if (duration[0] && duration[1]) {
        return DateTime.fromISO(end).diff(start, 'days').toObject().days > 2
      }
      if (duration[0]) {
        return DateTime.now().diff(start, 'days').toObject().days > 2
      }
      return false
    },
    getRowHeight(params) {
      let hidden = !!params?.data?.wiskRowHidden

      return hidden || (params?.node?.group ? 30 : 70)
    },
    openAddCustomField() {
      this.setGlobalAction({ type: 'customFieldEdit', action: { target: 'inventory' } })
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'inventory' } })
    },
    getRowHeightInner(params) {
      let node = params.node || {},
        children = node.allLeafChildren || [],
        hidden = params?.data?.wiskRowHidden

      if (!hidden && children?.length) {
        hidden = children.every(child => child?.data?.wiskRowHidden)
      }

      return (hidden && 1) || (params.node.group ? 30 : 50)
    },
    getSubtractFromAllChildrenCount(params) {
      if (params.wiskGroupActions && !params.node.level) {
        return 1
      }
      return 0
    },
    setAddBottleVisible() {
      this.addItemVisible = true
    },
    onAddItemHide() {
      this.selectedItemId = null
      this.selectedItem = null
      this.locationId = null
      this.addItemVisible = false
    },
    toggleArchive() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            if (this.inventory) { //this should never be null, but there was a case in sentry
              this.updateInventory({ type: 'archive', value: !this.inventory.archived })
            }

            setTimeout(() => {
              this.setGlobalAction({ type: 'inventoryDetails', action: null })
            }, 1000)
          },
          message: this.inventory.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.inventory.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    setDescription() {
      if (this.$refs.confirmDialog && this.inventory && this.inventory.id) {
        this.$refs.confirmDialog.prompt({
          callback: (value = '') => {
            this.updateInventory({ type: 'description', value })
          },
          message: this.translations.txtGenericDescription,
          title: this.translations.txtGenericAddDescription,
          text: this.inventory.description || '',
          required: false
        })
      }
    },
    getInventoryReportSpreadsheet() {
      if (this.inventory && this.inventory.id) {
        this.inventoryReportItemXlsLoading = true
        api.downloadBlob(
          'inventory/spreadsheet',
          `Inventory started on ${formatDate(this.inventory.started_at, { format: 'dd-LL-yyyy--HH-mm-ss' })} for ${this.venue.title}.xlsx`,
          {
            inventory_id: this.inventory.id
          }
        )
          .then(() => {
            this.inventoryReportItemXlsLoading = false
          })
          .catch(() => {
            this.inventoryReportItemXlsLoading = false
          })
      }
    },
    getInventoryReportByLocationSpreadsheet() {
      if (this.inventory && this.inventory.id) {
        this.inventoryReportLocationXlsLoading = true
        api.downloadBlob(
          'inventory/spreadsheet/area',
          `Inventory started on ${formatDate(this.inventory.started_at, { format: 'dd-LL-yyyy--HH-mm-ss' })} for ${this.venue.title}.xlsx`,
          {
            inventory_id: this.inventory.id
          }
        )
          .then(() => {
            this.inventoryReportLocationXlsLoading = false
          })
          .catch(() => {
            this.inventoryReportLocationXlsLoading = false
          })
      }
    },
    emailInventoryReportSummaryPdf() {
      if (this.inventory && this.inventory.id) {
        api.emailPdf(
          `inventory/${this.inventory.id}/pdf`,
          // `Inventory started on ${formatDate(this.inventory.started_at, { format: 'dd-LL-yyyy--HH-mm-ss' })} for ${this.venue.title}.pdf`,
          {
            location_ids: this.selectedLocations.map(l => l.id)
          }
        )
      }
    },
    emailInventoryBarcodesPdf() {
      if (this.inventory && this.inventory.id) {
        api.emailPdf(`inventory/${this.inventory.id}/barcodes/pdf`,
          // `Inventory barcodes for ${this.venue.title}.pdf`,
          {
            location_ids: this.selectedLocations.map(l => l.id)
          })
      }
    },
    getLocationsSpreadsheet() {
      if (this.inventory && this.inventory.id) {
        this.itemsByLocationXlsLoading = true
        api.downloadBlob('inventory/locations/spreadsheet', `Inventory locations for ${this.venue.title}.xlsx`, {
          inventory_id: this.inventory.id
        })
          .then(() => {
            this.itemsByLocationXlsLoading = false
          })
          .catch(() => {
            this.itemsByLocationXlsLoading = false
          })
      }
    },
    emailReports() {
      this.updateInventory({ type: 'resend_reports' })
    },
    lock() {
      if (this.$refs.confirmDialog && this.inventory && this.inventory.id) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.updateInventory({ type: 'lock' })
          },
          message: this.translations.txtInventoriesLockConfirmationDescription,
          title: this.translations.txtInventoriesLockConfirmationTitle,
        })
      }
    },
    unlock() {
      if (this.$refs.confirmDialog && this.inventory?.id) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.updateInventory({ type: 'unlock' })
          },
          message: this.translations.txtInventoriesUnlockConfirmationDescription,
          title: this.translations.txtInventoriesUnlockConfirmationTitle,
        })
      }
    },
    onItemFieldChange({ itemId, operation }) {
      this.updateBottle({ id: itemId, operation })
    },
    refreshFromDetails() {
      if (this.editAction.onChange) {
        this.editAction.onChange()
      }

      //seems like this is not needed because the real time update is already refreshing the data
      // this.getInventory()
    },
    updateInventory(operation) {
      this.loading = true

      if (this.inventory) {
        this.setInventory({ id: this.inventory.id, operation })
          .then(() => {
            if (this.editAction.onChange) {
              this.editAction.onChange()
            }
            this.getInventory()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    openEditItemInLocation(itemId, locationId) {
      this.locationId = locationId
      this.selectedItemId = itemId
    },
    getVariantIdFromItemId(itemId) {
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids[0]
    },
    setLocalInventoryData(inventory) {
      this.items = []
      this.inventoryDuration = [inventory.started_at, inventory.finished_at]
      this.inventory = inventory

      if (this.viewMode === 'item_location' && inventory?.items?.value) {
        this.filterLocations = uniqWith(
          inventory.items.value.map(item => item.location),
          isEqual
        )

        if (this.editAction.locationId) {
          let found = this.filterLocations.find(l => l.id === this.editAction.locationId)
          if (found) {
            this.selectedLocations = [found]
          }
        }
      }

      let items = [],
        prepareVariantInfo = variantInfo => {
          let variant = get(this.itemVariantsById, `${variantInfo.item_distributor_id}`)

          if (!variant) {
            return {}
          }
          return {
            ...variantInfo,
            title:
              variant.title + ` - (${variant.measurement.alias ? variant.measurement.alias + ' - ' : ''}${variant.measurement.quantity} ${variant.measurement.unit_of_measurement})`
          }
        }

      const measurements = []
      this.inventoryData.current.locations.forEach(l => {
        measurements.push(...l.measurements)
      })
      for (let i = 0; i < this.inventory.items.value.length; i++) {
        let inventoryItem = this.inventory.items.value[i],
          item = this.bottlesById[inventoryItem.item_id]

        inventoryItem.stats = inventoryItem.stats || { dollars: 0 }
        inventoryItem.stats.locations = inventoryItem.stats.locations || [merge({}, inventoryItem)]

        inventoryItem.stats.item_distributors = get(inventoryItem, 'stats.item_distributors', []).map(prepareVariantInfo)

        inventoryItem.stats.locations.forEach(l => {
          l.stats = l.stats || {}

          l.stats.item_distributors = get(l, 'stats.item_distributors', []).map(prepareVariantInfo)
        })

        if (item) {
          items.push({
            ...item,
            titleSuffix: '',
            inventoryItem,
            inventoryItemMeasurement: measurements.find(m => m.item_id === item.item_id),
            combined_id: inventoryItem.item_id + '_' + ((inventoryItem.location && inventoryItem.location.id) || '') + '_' + inventoryItem.order || '',
            added_at_timestamp: new Date(item.added_at).getTime()
          })
        } else {
          console.warn('missing', inventoryItem.item_id)
        }
      }

      items.sort((a, b) => compareNumbers(get(a, 'inventoryItem.order'), get(b, 'inventoryItem.order')))
      this.items = items
      this.loading = false
    },
    getInventory() {
      if (this.viewMode !== 'timeline' && this.editAction.id) {
        // this.loading = true

        const inventoryPromise = api.inventory(this.editAction.id),
          inventoryFlatPromise = api.inventoryFlat(this.editAction.id, { flatten_by: this.viewMode, expand_batches: this.expandBatches })

        Promise.all([inventoryPromise, inventoryFlatPromise])
          .then(([inventoryData, inventory]) => {
            this.inventoryData = inventoryData
            this.setLocalInventoryData(inventory)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.inventory = null
          })
      }
    },
    updateInventoryStartDate(operation) {
      this.inventoryDuration[0] = operation.value
      this.updateInventory(operation)
    },
    updateInventoryDuration(inventoryDuration) {
      const durationMoreThan2Days = this.inventoryDurationValidator(inventoryDuration)
      this.$refs.inventoryDurationConfirmDialog.confirm({
        callback: () => {
          this.updateInventory({ type: 'interval', value: { start: inventoryDuration[0], end: inventoryDuration[1] } })
          this.inventoryDuration = inventoryDuration
        },
        message: this.translations.confirmInventoryDurationUnusualText,
        title: this.translations.confirmInventoryDurationUnusualTitle,
        autoConfirm: !durationMoreThan2Days
      })
    },
    getGridColumns() {
      this.columns = getItemsGridColumns({
        translations: this.translations,
        openEdit: this.openEditItemInLocation,
        viewMode: this.viewMode,
        subrecipesByInventoryItemId: this.subrecipesByInventoryItemId
      })

    }
  },
  watch: {
    firestoreInitialLoadComplete() {
      let viewMode = this.viewMode
      this.viewMode = 'item'

      setTimeout(() => {
        this.viewMode = viewMode
      }, 0)
    },
    selectedItemId() {
      if (this.selectedItemId) {
        this.selectedItem = this.bottlesById[this.selectedItemId]
      }
    },
    addItemVisible() {
      if (this.addItemVisible && this.itemIdFilter && !this.selectedItemId) {
        this.selectedItem = this.bottlesById[this.itemIdFilter]
      }
    },
    editAction: {
      handler(editAction) {
        this.getGridColumns()

        if (editAction) {
          if (editAction.id) {
            this.viewMode = editAction.viewMode || this.viewMode
            this.expandBatches = !!editAction.expandBatches

            if (editAction.tab) {
              let views = ['item', 'item_location', 'timeline']
              this.viewMode = views[editAction.tab]
            }
          } else {
            this.inventory = { ...newInventory }
            this.inventoryDuration = [new Date(), new Date()]
          }
          this.itemIdFilter = editAction.itemIdFilter
        } else {
          this.inventory = null
          this.inventoryDuration = []
        }
      },
      immediate: true
    },
    viewMode: {
      handler() {
        if (!this.editAction.locationId) {
          this.selectedLocations = []
        }
        this.getInventory()
        this.getGridColumns()
      }
    },
    expandBatches: 'getInventory',
    inventoryEntriesImmutable: 'getInventory',
    inventoryLiveWatcherComputed(inventoryLiveWatcherComputed, prev) {
      if (prev) {
        this.getInventory()
      }
    }
  }
}
</script>

<template>
  <div class="mask-input-container material-design-input" :class="{ 'plain-text has-value': showZero && value === 0 || !!value, readonly }">
    <div class="text-start" v-if="realPlainText">
      <span v-if="label"> {{ label }}: </span>
      {{ formattedValue }}
    </div>

    <template v-if="!realPlainText">
      <div v-if="showPlainTextLocal || showPlainText" class="plain-text-span fill-width-height" :class="[className, { disabled, showPlainText, 'text-muted': disabled }]"
        @click="startEditing" @focus="startEditing" tabindex="0">
        {{ formattedValue }}
      </div>

      <input v-else
        v-bind="$attrs"
        class="mask-input"
        autocomplete="off"
        :readonly="readonly"
        data-lpignore="true"
        :class="className"
        :disabled="disabled"
        ref="numberInput" :id="id" :key="id"
        @focus="onFocus"
        @input="onInput"
        @keydown="onKeydown"
        @blur="onBlur"
        :value="value"
        type="tel" />

      <label v-if="label || infoTooltipVisible">
        <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
        {{ label }}

        <slot name="label-append"></slot>
      </label>

      <div v-if="suffix && forceSuffixInFocus && !showPlainTextLocal && !showPlainText" class="forced-suffix">
        {{ suffix }}
      </div>
    </template>
  </div>
</template>

<script>
import toNumber from 'lodash.tonumber'
import isFinite from 'lodash.isfinite'
import { formatNumber, round } from '@/modules/utils'

export default {
  name: 'MaskInput',
  emits: ['input', 'focus', 'blur', 'change'],
  props: {
    showPlainText: Boolean,
    realPlainText: Boolean,
    forceSuffixInFocus: Boolean,
    decimalsAsNeeded: Boolean,
    selectOnFocus: { type: Boolean, default: true },
    noBlurOnEnter: Boolean,
    transformerIn: { type: Function, default: z => z },
    transformerOut: { type: Function, default: z => z },
    customFormatter: Function,
    customUnFormatter: Function,
    disabled: Boolean,
    infoTooltipKey: String,
    infoTooltipParams: { type: [Object, null], default: () => null },
    readonly: Boolean,
    allowEmpty: Boolean,
    className: null,
    autofocus: Boolean,
    showZero: Boolean,
    label: String,
    prefix: String,
    suffix: String,
    min: Number,
    max: Number,
    defaultIfEmpty: { type: String, default: '' },
    decimalSeparator: { type: String, default: '.' },
    thousandsSeparator: { type: String, default: ',' },
    decimals: { type: Number, default: 0 },
    minDecimals: { type: Number, default: 0 },
    id: String
  },
  data() {
    return {
      value: null,
      infoTooltipVisible: false,
      showPlainTextLocal: true,
      stopBlur: false,
      focused: false
    }
  },
  computed: {
    formattedValue() {
      return this.format(this.value)
    }
  },
  methods: {
    startEditing() {
      if (!this.disabled && !this.readonly) {
        this.showPlainTextLocal = false
        setTimeout(() => {
          if (this.$refs.numberInput) {
            this.$refs.numberInput.focus()
          }
        }, 100)
      }
    },
    //called from outside
    focus() {
      this.startEditing()
    },
    format(value) {
      if (this.customFormatter) {
        return this.customFormatter(value)
      }
      if (!value) {
        return this.showZero ? 0 : ''
      }

      let formatted = formatNumber(value, {
        decimals: this.decimals,
        decimalSeparator: this.decimalSeparator,
        thousandsSeparator: this.thousandsSeparator,
        decimalsAsNeeded: this.decimalsAsNeeded,
        minDecimals: this.minDecimals,
        defaultIfEmpty: this.defaultIfEmpty
      })

      if (this.prefix) {
        formatted = this.prefix + formatted
      }
      if (this.suffix) {
        formatted += this.suffix
      }

      return formatted
    },
    onKeydown(event = {}) {
      if (event.key === 'Enter' && !this.noBlurOnEnter && this.$refs?.numberInput?.blur) {
        this.$refs.numberInput.blur()
      }
    },
    onInput(event) {
      if (event.target.value.endsWith(this.decimalSeparator)) {
        return
      }

      let value = toNumber(event.target.value) || 0
      value = (isFinite(value) && value) || 0

      if (isFinite(this.max) && value > this.max) {
        value = this.max
      }
      if (isFinite(this.min) && value < this.min) {
        value = this.min
      }

      if (!this.decimals && value) {
        this.callInput(Math.floor(value))
      } else if (this.decimals && value) {
        this.callInput(round(value, this.decimals))
      } else {
        this.callInput(value)
      }
    },
    onFocus() {
      this.focused = true

      if (!this.disabled) {
        setTimeout(() => {
          if (this.selectOnFocus && this.$refs.numberInput && this.$refs.numberInput.select) {
            this.$refs.numberInput.select()
          }
        }, 0)
        this.$emit('focus')
      }
    },
    onBlur(event) {
      this.focused = false
      this.showPlainTextLocal = true

      if (!event.target.value && this.allowEmpty) {
        this.$emit('blur', { stop: true })
      } else if (this.value !== null && !Number.isNaN(this.value)) {
        this.$emit('blur', { value: this.transformerOut(this.value), stop: this.stopBlur })
      }

      this.stopBlur = false
    },
    callInput(value) {
      this.value = toNumber(value) || 0

      this.$emit('input', this.transformerOut(this.value))
      this.$emit('change', this.transformerOut(this.value))
    },
    setValue(value) {
      this.value = this.transformerIn(toNumber(value) || 0)

      //magic number needed to trigger change to value 0 in floating actions where we have no way to have a value for multiple items selected in the grid
      if (this.value === -1111111) {
        this.value = 0
      }
    }
  },
  mounted() {
    if (this.autofocus) {
      this.focus()
    }
    setTimeout(() => {
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  }
}
</script>

<style lang="scss">
.mask-input {
  width: 100%;
  min-height: 33px;
  border-radius: 4px;

  &.plain-text:not(:focus) {
    cursor: pointer;
    border: none;
    outline: none;
    // background-color: transparent;
    color: inherit;
    box-shadow: none;
    padding: 0;
    text-align: center;
  }

  &.plain-text:read-only:focus {
    cursor: pointer;
    border: none;
    outline: none;
    // background-color: transparent;
    color: inherit;
    box-shadow: none;
    padding: 0;
    text-align: center;
  }
}

.mask-input-container {
  &.plain-text {
    background-color: white;
  }

  .plain-text-span {
    background-color: white;
  }

  .forced-suffix {
    pointer-events: none;
    position: absolute;
    top: 6px;
    right: 10px;
    color: #181818;
  }

  // span {
  /*For Dashlane*/
  // display: none !important;
  // }
}
</style>

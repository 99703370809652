<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'inventoryEdit', action: null })" :title="modalTitle" size="lg" fullHeight hideFooter hideHeaderExtra>

    <template v-slot:modal-title>

      <b-row v-if="inventory" no-gutters>
        <!-- <b-button variant="link" @click="callReferPopup">
          {{ translations.txtUsersReferInvite }}
        </b-button> -->
        <b-col cols="5" :class="{ warning: inventory.archived }">
          <h5 class="modal-title"> {{ modalTitle }} </h5>
        </b-col>
        <b-col cols="7" style="padding-right: 2rem !important;">
          <dateRangeSelector v-if="allLocationsCompleted && inventory.approved_by" :shortcuts="false" class="float-start" @update:modelValue="updateInventoryDuration"
                             :missingEndLabel="translations.txtInventoriesFinishedAtNon" :modelValue="inventoryDuration" :validator="inventoryDurationValidatorWithText">
            <template v-slot:prepend>
              <icon v-if="inventoryDurationUnusual" name="wisk-warning" class="text-danger float-start me-1 mt-1" style="margin-left: -5px" :scale="0.7" v-tooltip="translations.confirmInventoryDurationUnusualTitle" />
            </template>
          </dateRangeSelector>

          <wiskInput v-else :label="translations.txtGenericStartDate" class="float-start" :modelValue="inventoryDuration[0]" @operation="updateInventoryStartDate" operation="start_date" inputType="datepicker" required />

          <b-button v-if="!inventory.approved_by && allLocationsCompleted" class="float-end" @click="approve" size="sm" variant="primary" :disabled="!currentPermissionsByType.inventory_submit">
            {{ translations.txtInventoriesApprove }}
          </b-button>

          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" class="float-end" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert v-if="inventoryOngoing && inventoryDurationUnusual" variant="warning" show class="mb-2 text-center">
            {{ translations.txtInventoryUnusualDuration }}
            <infoTooltip helpKey="58cdcbd5-9ccb-401b-9bed-5fa5b2bd6130" />
          </b-alert>
          <b-alert v-if="!inventory?.is_locked && inventoryData?.current?.scheduled_locking_at" variant="warning" show class="mt-2 mb-0 text-center">
            {{ translations.translate('tplInventoryDetailsScheduledLock', { '{a}': formatDate(inventoryData?.current?.scheduled_locking_at) }) }}
          </b-alert>
        </b-col>
      </b-row>
    </template>

    <inventoryLocationSelector @select="locationId = $event" @reset="resetLocation" @allDone="allLocationsCompleted = $event"
      v-if="editAction && editAction.id && !locationId && entriesByLocationId" :inventoryId="editAction.id" :inventory="inventoryData" :entriesByLocationId="entriesByLocationId" />

    <wiskLoading loading v-if="!entriesByLocationId || !inventory" />

    <b-button v-if="customFieldsByTarget && !customFieldsByTarget.inventory" @click="setGlobalAction({ type: 'customFieldEdit', action: { target: 'inventory' } })"
      class="mt-3" variant="primary">
      <span> {{ translations.txtCustomFieldAdd }} </span>
    </b-button>

    <customFields v-if="inventoryData?.current" target="inventory" :item="inventoryData.current" @operation="save" />

    <wiskModal v-model="pairEditorVisible" extraLarge size="lg" fullHeight hideFooter :title="modalTitle" keepAlive>
      <template v-slot:modal-header-extra>
        <b-form-radio-group size="sm" buttons v-model="viewMode" name="viewMode" button-variant="outline-primary">
          <b-form-radio value="entries"> {{ translations.txtInventoriesEntries }} </b-form-radio>
          <b-form-radio value="timeline"> {{ translations.txtGenericTimeline }} </b-form-radio>
        </b-form-radio-group>

        <b-button v-if="currentInventoryLocation && !currentInventoryLocation.finished_at" class="float-end text-start me-2" size="sm" variant="primary" @click.stop="finishLocation">
          {{ translations.txtInventoryMarkAsCompleted }}
        </b-button>

      </template>

      <inventoryPairEdit v-if="viewMode === 'entries' && editAction?.id && recentInventoriesById[editAction.id] && pairEditorVisible && entriesByLocationId && entriesByLocationId[locationId]" @refresh="getData"
        :inventoryId="editAction.id" :locationId="locationId" :inventoryData="inventoryData" :entries="entriesByLocationId[locationId]" @refreshEntry="refreshEntry" />

      <timeline v-if="viewMode === 'timeline'" parentGridName="InventoryEditAreaTimeline" :filters="[{ type: 'inventory_location', id: currentInventoryLocation.id }]" showSearch />
    </wiskModal>

    <confirm ref="confirmDialog" />
    <confirm ref="inventoryDurationConfirmDialog">
      <a href="https://help.wisk.ai/en/articles/5986898" target="_blank">{{ translations.txtGenericLearnMore }}</a>
    </confirm>
    <confirm ref="confirmDialogApprove" />


    <portal to="confirm-dialog">
      <confirm ref="confirmDialogSchedule" promptForTextType="datepicker" :promptForTextLabel="translations.txtGenericDate"/>
    </portal>
  </wiskModal>
</template>

<script>
import { toRefs } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import Noty from 'noty'
import get from 'lodash.get'
import merge from 'lodash.merge'
import api from '@/api'
import { compareNumbers, round, getBaseValueFromMeasurement, formatDate } from '@/modules/utils'
import { liveQueryHandler } from '@/modules/db'
import inventoryPairEdit from '@/components/inventories/InventoryPair'
import inventoryLocationSelector from '@/components/inventories/InventoryLocationSelector'
import dateRangeSelector from '@/components/common/DateRangeSelector'
import customFields from '@/components/customFields/CustomFieldsRender'
import timeline from '@/components/timeline/Timeline'

export default {
  name: 'InventoryEdit',
  setup(props) {
    const { editAction } = toRefs(props)
    return {
      inventoryEntriesImmutable: liveQueryHandler({ type: 'inventory_location_measurement', key: 'inventory_id', payload: editAction.value.id })
    }
  },
  components: { inventoryPairEdit, inventoryLocationSelector, dateRangeSelector, customFields, timeline },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      locationId: 0,
      locationIds: [],
      allLocationsCompleted: false,
      inventoryData: null,
      pairEditorVisible: false,
      entriesByLocationId: null,
      inventoryDuration: [],
      currentInventoryLocation: null,
      prepareEntriesTimeoutId: null,
      viewMode: 'entries',
      inventoryEntries: []
    }
  },
  computed: {
    ...mapState(['recentInventoriesById', 'venue', 'bottlesById', 'translations', 'inventoryLocationsByInventoryId', 'currentPermissionsByType', 'locationsById', 'user', 'customFieldsByTarget']),
    ...mapGetters(['activeLocations']),
    inventoryDurationUnusual() {
      if (this.inventory) {
        return this.inventoryDurationValidator([this.inventory.started_at, this.inventory.finished_at])
      }
      return false
    },
    inventoryOngoing() {
      return this.inventory && (!this.allLocationsCompleted || !this.inventory.approved_by || !this.inventory.finished_at)
    },
    inventory() {
      if (this.editAction?.id) {
        return get(this.recentInventoriesById, `${this.editAction.id}.pair.current`, null)
      }

      return null
    },
    pageActions() {
      let actions = []

      if (this.inventory?.id && this.currentPermissionsByType.inventory_archive) {
        if (!this.inventory.is_locked && this.currentPermissionsByType.inventory_archive) {
          actions.push(
            { key: 7, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.inventory.archived, action: this.toggleArchive },
            { key: 8, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.inventory.archived, action: this.toggleArchive }
          )
        }
        if (this.currentPermissionsByType.inventory_manage) {
          actions.push(
            { key: 85, title: this.translations.txtCustomFieldsView, icon: 'wisk-options', variant: 'primary', action: this.openCustomFields },
          )
        }
      }
      return actions
    },
    modalTitle() {
      if (this.inventory?.id && this.locationId && this.locationsById[this.locationId]) {
        let location = this.locationsById[this.locationId]
        return (
          this.translations.translate('txtInventoriesEdit', {
            '{date}': DateTime.fromISO(this.inventory.started_at).toRelative(),
            '{user}': (this.inventory.user && this.inventory.user.name) || ''
          }) +
          ` - ${location.title}` +
          (location.archived ? ` - (${this.translations.txtGenericArchived})` : '')
        )
      }
      if (this.inventory?.id) {
        return this.translations.translate(this.inventoryOngoing ? 'tplInventoriesEditTitleOngoing' : 'tplInventoriesEditTitle', {
          '{a}': this.inventory?.user?.name || ''
        })
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setOnDemandState', 'setInventory', 'notify']),
    formatDate,
    inventoryDurationValidatorWithText(duration) {
      const isNotValid = this.inventoryDurationValidator(duration)
      if (duration[0] && duration[1]) {
        return isNotValid && this.translations.confirmInventoryDurationUnusualTitle
      }
      if (duration[0]) {
        return isNotValid && this.translations.txtInventoryUnusualDuration
      }
      return false
    },
    inventoryDurationValidator(duration) {
      const start = duration[0]?.getTime ? DateTime.fromJSDate(duration[0]) : DateTime.fromISO(duration[0]),
        end = duration[1]?.getTime ? DateTime.fromJSDate(duration[1]) : DateTime.fromISO(duration[1])
      if (duration[0] && duration[1]) {
        return DateTime.fromISO(end).diff(start, 'days').toObject().days > 2
      }
      if (duration[0]) {
        return DateTime.now().diff(start, 'days').toObject().days > 2
      }
      return false
    },
    toggleArchive() {
      if (this.inventory && this.$refs.confirmDialog) {
        let value = !this.inventory.archived

        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ type: 'archive', value })
          },
          message: this.inventory.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.inventory.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    save(operation) {
      this.setInventory({ id: this.inventory.id, operation })
      if (operation.type === 'archive') {
        this.setGlobalAction({ type: 'inventoryEdit', action: null })
      }
    },
    callReferPopup() {
      this.setGlobalAction(({
        type: 'adPopup',
        action: {
          title: 'Earn $500 for Every Restaurant You Refer!',
          use: 'refer',
          hideFooter: true,
          callback: () => {
            this.setGlobalAction(({ type: 'referUsers', action: {} }))
          }
        }
      }))
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'inventory' } })
    },
    saveInventoryLocation(operation, locationId = this.locationId) {
      this.inventory?.id && api.updateInventoryLocation(this.inventory.id, { locationId, operation })
    },
    wrapOperationForOfflineCollection(operation, type, typeId, subType = null, subTypeId = null) {
      return {
        type,
        subtype: subType,
        type_id: typeId,
        secondary_type_id: subTypeId,
        user_id: this.user.id,
        venue_id: this.venue.id,
        value: [{ ...operation, date: new Date().toISOString(), platform: 'web' }]
      }
    },
    finishLocation() {
      if (!this.currentInventoryLocation.started_at) {
        this.saveInventoryLocation({ type: 'start' })
      }

      this.saveInventoryLocation({ type: 'submit' })

      setTimeout(() => {
        this.locationId = 0
      }, 1000)
    },
    resetLocation(locationId) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.saveInventoryLocation({ type: 'reset' }, locationId)

            this.entriesByLocationId[locationId].forEach(entry => {
              this.refreshEntry({ ...entry, REMOVE: true })
            })
          },
          message: this.translations.confirmInventoryLocationResetText,
          title: this.translations.confirmInventoryLocationResetTitle
        })
      }
    },
    updateInventoryStartDate(operation) {
      this.inventoryDuration[0] = operation.value

      this.save(operation)
    },
    updateInventoryDuration(inventoryDuration) {
      const durationMoreThan2Days = this.inventoryDurationValidator(inventoryDuration)
      this.$refs.inventoryDurationConfirmDialog.confirm({
        callback: () => {
          this.save({ type: 'interval', value: { start: inventoryDuration[0], end: inventoryDuration[1] } })
          this.inventoryDuration = inventoryDuration
        },
        message: this.translations.confirmInventoryDurationUnusualText,
        title: this.translations.confirmInventoryDurationUnusualTitle,
        autoConfirm: !durationMoreThan2Days
      })
    },
    promptNextInventoryDate() {
      this.notify({
        text: this.translations.translate('tplInventoryScheduledNext', { '{a}': formatDate(this.inventoryData.current.next_inventory_scheduled_at) }),
        buttons: [
          Noty.button(
            this.translations.txtInventoryScheduledNextChange,
            'btn btn-primary btn-sm  mx-1',
            () => {
              Noty.closeAll()

              if (this.$refs.confirmDialogSchedule) {
                this.$refs.confirmDialogSchedule.confirm({
                  title: this.translations.txtDashboardInventoryNextSchedule,
                  promptForText: true,
                  defaultPromptForTextValue: this.inventoryData.current.next_inventory_scheduled_at,
                  callback: (payload) => {
                    this.save({ type: 'next_inventory_scheduled_at', value: payload })
                  }
                })
              }
            },
            { id: 'button1', 'data-status': 'ok' }
          )
        ],
        innerType: 'alert',
        timeout: 30000,
        type: 'makeToast'
      })
    },
    approve() {
      if (this.inventoryData?.current && this.$refs.confirmDialogApprove) {
        this.$refs.confirmDialogApprove.confirm({
          callback: () => {
            let endDate = new Date()

            this.save({ type: 'approve', value: { start: this.inventoryData.current.started_at, end: endDate.toISOString() } })

            setTimeout(() => {
              this.inventoryDuration = [this.inventoryDuration[0], endDate]

              // this.callReferPopup()
            }, 500)
          },
          message: this.translations.confirmInventoryApproveText,
          title: this.translations.confirmInventoryApproveTitle
        })
      }
    },
    getData() {
      if (this.inventory) {
        let previousApprovedBy = this.inventoryData?.current?.approved_by,
          previousInventoryDataLoaded = !!this.inventoryData

        api.inventory(this.inventory.id).then(inventoryData => {
          this.inventoryData = inventoryData

          //normally we should call this popup after the inventory is approved, but the approve call takes a while to complete
          if (previousInventoryDataLoaded && inventoryData?.current?.approved_by && !previousApprovedBy ) {
            this.promptNextInventoryDate()
          }
        })

        this.inventoryDuration = [this.inventory.started_at, this.inventory.finished_at]
      }
    },
    refreshEntry(entry) {
      let found = this.inventoryEntries.find(e => e.id === entry.id)

      if (found) {
        if (entry.REMOVE) {
          this.inventoryEntries = this.inventoryEntries.filter(e => e.id !== entry.id)
        } else {
          this.inventoryEntries = this.inventoryEntries.map(e => e.id === entry.id ? entry : e)
        }
      } else {
        this.inventoryEntries = [...this.inventoryEntries, entry]
      }
    },
    prepareEntries() {
      if (this.editAction?.id && this.activeLocations && this.bottlesById && this.inventoryEntries) {
        this.entriesByLocationId = null
        this.locationIds = get(this.inventoryLocationsByInventoryId, `${this.editAction.id}`, []).map(l => l.location.id)

        let locationIds = [...new Set([...this.activeLocations.map(l => l.id), ...this.locationIds])],
          entriesByLocationId = {}

        locationIds.forEach(id => {
          let entries = this.inventoryEntries.filter(e => e.location_id === id)
          entries.sort((a, b) => compareNumbers(a.inventory_order, b.inventory_order))

          entries.forEach(entry => {
            let lowLevelMeasurement = getBaseValueFromMeasurement(get(entry, 'measurement.item_distributor_measurement', get(entry, 'measurement.item_measurement'))),
              entryType = get(entry, 'measurement.measurement.type')

            entry.units_computed = 0

            switch (entryType) {
              case 'unit':
                entry.units_computed += get(entry, 'measurement.measurement.quantity', 0)
                break
              case 'case':
                entry.units_computed += get(entry, 'measurement.measurement.quantity', 0) * get(entry, 'measurement.measurement.case_size', 0)
                break
              case 'slider':
                entry.units_computed += get(entry, 'measurement.measurement.percentage_remaining', 0)
                break
              case 'scale':
                entry.units_computed += get(entry, 'measurement.measurement.ml_left', 0) / lowLevelMeasurement
                break
              case 'manual':
                entry.units_computed += getBaseValueFromMeasurement(get(entry, 'measurement.measurement', {})) / lowLevelMeasurement
                break
              case 'partial':
                entry.units_computed += get(entry, 'measurement.measurement.quantity', 0) / get(entry, 'measurement.measurement.out_of', 1)
                break
              default:
                break
            }
            entry.units_computed = round(entry.units_computed, 2)
          })
          entriesByLocationId[id] = [...entries]
        })

        this.entriesByLocationId = { ...entriesByLocationId }
      }
    }
  },
  watch: {
    inventoryLocationsByInventoryId: {
      immediate: true,
      handler() {
        if (this.inventory?.id) {
          this.locationIds = get(this.inventoryLocationsByInventoryId, `${this.inventory.id}`, []).map(l => l.location.id)
          this.prepareEntries()
        }
      }
    },
    inventoryEntries: { immediate: true, handler: 'prepareEntries' },
    inventoryEntriesImmutable: {
      immediate: true,
      handler() {
        this.inventoryEntries = this.inventoryEntriesImmutable.map(e => merge({}, e))
      }
    },

    locationId() {
      this.pairEditorVisible = !!this.locationId
      this.viewMode = 'entries'

      if (this.locationId && this.inventoryLocationsByInventoryId && this.inventoryLocationsByInventoryId[this.editAction.id]) {
        this.currentInventoryLocation = this.inventoryLocationsByInventoryId[this.editAction.id].find(l => l.location.id === this.locationId)
      }

      this.getData()
    },
    pairEditorVisible(pairEditorVisible) {
      if (!pairEditorVisible) {
        this.locationId = 0
      }
    },
    inventory: {
      immediate: true,
      handler: 'getData'
    },
    activeLocations: {
      immediate: true,
      deep: true,
      handler: 'prepareEntries'
    }
  }
}
</script>

<style lang="scss"></style>

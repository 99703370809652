<template>
  <div>
    <span v-if="showPlainText">
      {{ tooltip }}
    </span>

    <b-row v-if="!showPlainText" noGutters :class="{ highlighted }" :title="tooltip">
      <b-col :class="[{ 'additional-button-present': operationTriggerButtonComputed || (addNewItem && addNewItem.showButtonIfNoId && localValue && trackBy && !localValue[trackBy]) }, colClass]">
        <div v-if="displayAsButtonGroup" ref="buttonGroup" :class="{ 'no-border': displayAsButtonGroup && !label, 'w-100': expandButtonGroup, 'material-design-input-multiselect-as-buttons': buttons }">
          <b-form-radio-group ref="radioGroup" :size="buttonGroupSize" :buttons="buttons" :vertical="!buttons" @update:modelValue="onInput" @change="onSelect" :disabled="disabledLocal" style="z-index: 0;"
            button-variant="outline-primary" :value-field="trackBy" text-field="title" v-model="valueForRadio" :class="{ 'w-100': expandButtonGroup }">
            <b-form-radio v-for="item in computedItems" :value="item[trackBy]" :key="item[trackBy]" :disabled="disabledLocal">
              {{ item.title }}
            </b-form-radio>

            <b-button v-if="addNewItem" variant="outline-primary" @click="addNewItem.action(domId, searchQuery, value, callbackItemInjected)">
              <icon class="" name="wisk-plus" :scale="0.7" style="margin-top: -2px"></icon>
              {{ addNewItem.label }}
            </b-button>

            <b-button v-if="addNewItemIfMissing" variant="outline-primary" @click="addNewItemIfMissing.action(domId, searchQuery, value, callbackItemInjected)">
              {{ addNewItemIfMissing.label }}
            </b-button>
          </b-form-radio-group>

          <slot></slot>

          <label v-show="label">
            <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" style="pointer-events: auto;" />
            {{ label }}

            <slot name="label-append"></slot>
          </label>
        </div>

        <div v-if="!displayAsButtonGroup" class="form-control single_select_warpper material-design-input" :class="[validationClass, { 'has-value': (hasValue || searchQuery.length), 'no-value': (!hasValue || !searchQuery.length) }]" :style="{ width: width ? width + 'px' : '100%' }">
          <multiselect
            :id="domId"
            :options="computedItems"
            :modelValue="localValue"
            @update:modelValue="onInput"
            @close="shouldValidate = true; $emit('close')"
            :disabled="disabledLocal"
            :class="[`wisk-select-label-${label}`, inputClass]"
            :multiple="false"
            :placeholder="placeholder"
            :key="multiselectKey"
            :tagPlaceholder="tagPlaceholder"
            :selectLabel="selectLabel || translations.txtGenericClickSelect"
            :deselectLabel="emptyNotAllowed ? '' : deselectLabel || translations.txtGenericClickDeselect"
            :track-by="trackBy"
            :internal-search="false"
            @select="onSelect"
            @open="$emit('open', $event)"
            @search-change="$event => searchQuery = $event"
            ref="multiselect"
            :custom-label="customLabel"
            :style="{ width: width ? width + 'px' : '100%' }"
            :max-height="multiselectOptions?.maxHeight || 400"
            v-bind="multiselectOptions"
            :taggable="false"
            :allowEmpty="!emptyNotAllowed"
            :loading="loading">

            <template v-slot:noOptions>
              <span> {{ emptyListMessage }} </span>
            </template>

            <template v-slot:noResult>
              <b-button v-if="addNewItemIfMissing" variant="primary" @click="addNewItemIfMissing.action(domId, searchQuery, value, callbackItemInjected)">
                {{ addNewItemIfMissing.label }}
              </b-button>
              <span else> {{ translations.txtGenericNoResult }} </span>
            </template>

            <template v-slot:beforeList>
              <slot name="beforeList">
              </slot>
            </template>

            <template v-slot:afterList>
              <slot name="afterList">
                <li v-if="addNewItem" class="multiselect__element" style="padding: 5px 10px;">
                  <b-button variant="primary" @click="addNewItem.action(domId, searchQuery, value, callbackItemInjected)">
                    <icon class="" name="wisk-plus" :scale="0.7" style="margin-top: -2px"></icon>
                    {{ addNewItem.label }}
                  </b-button>
                </li>
              </slot>
            </template>

            <template v-slot:option="scope">
              <slot name="option" v-bind="scope || {}"></slot>
            </template>
            <template v-slot:singleLabel="scope">
              <slot name="singleLabel" v-bind="scope || {}"></slot>
            </template>
          </multiselect>
          <label v-show="label" class="single_select_label text-truncate">
            <infoTooltip v-if="infoTooltipVisible" :helpKey="infoTooltipKey" :params="infoTooltipParams" style="pointer-events: auto;" />
            {{ label + validationStar }}

            <slot name="label-append"></slot>
          </label>

          <div v-show="!isValid && shouldValidate" class="text-small-info ps-2 text-danger text-bold text-start input-helper-text">
            {{ validationMessage }}
          </div>
          <div v-show="(isValid || !shouldValidate) && helperText" class="text-small-info text-start ps-2 input-helper-text">
            {{ helperText }}
          </div>
        </div>

        <b-button variant="link" class="" style="position: absolute; right: 20px; top: 5px;" size="sm"
          @click="triggerOperation" :title="translations.txtGenericUse" v-if="operationTriggerButtonComputed">
          <icon class="" name="wisk-clone"></icon>
        </b-button>

        <b-button variant="link" class="" style="position: absolute; right: 20px; top: 5px;" size="sm"
          @click="addNewItem.action(domId, localValue.title || searchQuery, value, callbackItemInjected)" :title="addNewItem.label"
          v-if="addNewItem && addNewItem.showButtonIfNoId && localValue && trackBy && !localValue[trackBy]">
          <icon class="" name="wisk-plus"></icon>
        </b-button>

        <slot v-if="!displayAsButtonGroup"></slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import get from 'lodash.get'
import { mapState } from 'vuex'
import { guid, stringFilter } from '@/modules/utils'

export default {
  name: 'WiskSelect',
  emits: ['update:modelValue', 'select', 'operation', 'close', 'buttonGroupWidth', 'change', 'valid', 'open'],
  components: { multiselect },
  props: {
    modelValue: null,
    operationTriggerButton: Boolean,
    saveIfNoId: Boolean,
    displayAsButtonGroup: Boolean,
    buttons: { type: Boolean, default: true },
    expandButtonGroup: Boolean,
    infoTooltipKey: String,
    emptyListMessage: { type: String, default: 'List is empty' },
    infoTooltipParams: { type: [Object, null], default: () => null },
    buttonGroupSize: { type: String, default: 'sm' },
    placeholder: { type: String, default: ' ' },
    highlightOnValueChange: Boolean,
    triggerInputOnLoad: Boolean,
    triggerInputOnSet: Boolean,
    clearAfterEmitOperation: Boolean,
    label: { type: String, default: '' },
    helperText: { type: String, default: '' },
    valueName: String,
    trackBy: { type: String, default: 'id' },
    titleKey: { type: String, default: 'title' },
    inputClass: String,
    colClass: String,
    requiredValidationMessageOverride: String,
    customLabelOverride: Function,
    tagValidator: Function,
    forceNumber: Boolean, //only for tagging!
    customFieldValueWrapper: Object,
    tagPlaceholder: String,
    operation: String,
    selectLabel: String,
    deselectLabel: String,
    operationEmpty: String,
    disabled: Boolean,
    validations: Array,
    required: Boolean, //TODO: if needed move this into a validations object/array
    multiselectOptions: { type: Object },
    addNewItemIfMissing: Object,
    addNewItem: Object,
    width: { type: Number },
    items: {
      type: Array,
      default: () => []
    },
    showPlainText: Boolean,
    prefix: String,
    disableTooltip: Boolean,
    loading: Boolean
  },
  data() {
    return {
      isValid: false,
      initButtonGroupWidthTimeoutId: null,
      highlighted: false,
      disabledLocal: false,
      oldValue: null,
      localValue: null,
      infoTooltipVisible: false,
      valueForRadio: null,
      multiselectKey: 1,
      inputGroupParent: null,
      shouldValidate: false,
      searchQuery: '',
      extraItem: null,
      buttonGroupWidth: 0,
      operationTriggerButtonLocal: false,
      highlightOnValueChangeLocal: false,
      initDone: false
    }
  },
  computed: {
    ...mapState(['translations']),
    tooltip() {
      if (this.disableTooltip) {
        return ''
      }
      let tooltip = this.label ? this.label + ': ' : ''
      tooltip += this.localValue ? this.customLabel(this.localValue) : ''
      return tooltip
    },
    emptyNotAllowed() {
      return this.required || (this.multiselectOptions && this.multiselectOptions.allowEmpty === false)
    },
    validationStar() {
      if (this.label && (this.required || (this.validations && this.validations.length))) {
        return '*'
      }
      return ''
    },
    hasValue() {
      return !!this.localValue
    },
    validationClass() {
      if (this.required && this.shouldValidate) {
        return this.isValid ? 'is-valid' : 'is-invalid'
      }
      return ''
    },
    hasChange() {
      if (this.trackBy) {
        return !this.localValue || !this.oldValue || this.oldValue[this.trackBy] !== this.localValue[this.trackBy]
      }
      return !isEqual(this.localValue, this.oldValue)
    },
    canSetOperation() {
      return this.hasChange && this.isValid
    },
    computedItems() {
      let items = this.items.map(i => ({ ...i }))

      if (this.extraItem && !this.items.find(i => (i && this.trackBy && i[this.trackBy] === this.extraItem[this.trackBy]) || (!this.trackBy && this.extraItem === i))) {
        items.push(this.extraItem)
      }

      if (this.searchQuery) {
        if (this.multiselectOptions && this.multiselectOptions.groupValues) {
          items.forEach(item => {
            if (Array.isArray(item[this.multiselectOptions.groupValues])) {
              item[this.multiselectOptions.groupValues] = item[this.multiselectOptions.groupValues].filter(innerItem =>
                stringFilter('contains', `${this.customLabel(innerItem)} ${innerItem[this.trackBy]}`, this.searchQuery)
              )
            }
          })
          return items.filter(item => item[this.multiselectOptions.groupValues] && item[this.multiselectOptions.groupValues].length)
        }

        return items.filter(item => stringFilter('contains', `${this.customLabel(item)} ${item[this.trackBy]}`, this.searchQuery))
      }
      return items
    },
    domId() {
      return 'z-' + (this.label || '').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    },
    validationMessage() {
      return this.requiredValidationMessageOverride || this.translations.txtValidationRequired
    },
    operationTriggerButtonComputed() {
      return this.operationTriggerButton || this.operationTriggerButtonLocal
    },
    highlightOnValueChangeComputed() {
      return this.highlightOnValueChange || this.highlightOnValueChangeLocal
    },
    plainItems() {
      if (this.multiselectOptions && this.multiselectOptions.groupValues && this.items && this.items.length && this.items[0][this.multiselectOptions.groupValues]) {
        let arr = []
        this.items.forEach(item => {
          if (Array.isArray(item[this.multiselectOptions.groupValues])) {
            arr.push(...item[this.multiselectOptions.groupValues])
          }
        })
        return arr
      }
      return this.items
    }
  },
  methods: {
    activate() {
      this.$refs.multiselect && this.$refs.multiselect.activate()
    },
    deactivate() {
      this.$refs.multiselect && this.$refs.multiselect.deactivate()
    },
    focus() {
      if (this.$refs.multiselect && this.$refs.multiselect.activate) {
        this.$refs.multiselect.activate()
      }
    },
    callbackItemInjected(newItem) {
      if (newItem) {
        this.extraItem = newItem
        setTimeout(() => {
          this.onInput(newItem)
        }, 0)
      }
    },
    customLabel(item) {
      if (this.customLabelOverride) {
        return this.customLabelOverride(item)
      }

      if (item && typeof item === 'object') {
        return get(item, this.titleKey, '')
      }
      return item || ''
    },
    onSelect(event) {
      let value = event
      if (event && this.trackBy) {
        value = event[this.trackBy]
      }
      this.$emit('select', value)
    },
    onInput(item) {
      if (this.displayAsButtonGroup && this.trackBy) {
        let found = this.computedItems.find(i => i && i[this.trackBy] === item)
        this.$emit('update:modelValue', item)
        this.$emit('change', found)
        this.localValue = found
      } else {
        this.$emit('update:modelValue', this.trackBy && item ? item[this.trackBy] : item)
        this.$emit('change', item)
        this.localValue = item
      }

      setTimeout(() => {
        if (this.operation) {
          this.canSetOperation ? this.setOperation() : this.removeOperation()
        }
      }, 0)
    },
    prepareValue() {
      let value = this.modelValue

      if (this.trackBy && (typeof value === 'string' || typeof value === 'number') && Array.isArray(this.items)) {
        let found = this.items.find(i => i && i[this.trackBy] === this.modelValue)

        if (this.multiselectOptions && this.multiselectOptions.groupValues && Array.isArray(this.plainItems)) {
          found = this.plainItems.find(i => i && i[this.trackBy] === this.modelValue)
        }

        if (found) {
          value = { ...found }
        }
      }
      this.localValue = value
      this.oldValue = value
      this.valueForRadio = this.trackBy && value ? value[this.trackBy] : value

      if (this.triggerInputOnSet) {
        this.triggerOperation()
      }

      if (this.triggerInputOnLoad && !this.initDone) {
        this.triggerOperation()
      }
      this.initDone = true
    },
    triggerOperation() {
      let value = this.localValue

      this.oldValue = null

      this.setOperation()

      if (value === undefined) {
        value = null
      }
      this.inputGroupParent && this.inputGroupParent.$emit('value', { [this.valueName || this.operation]: value })
    },
    validate(localValue) {
      if (this.required) {
        if (this.trackBy && localValue && !isNil(localValue[this.trackBy])) {
          return true
        }
        if (!this.trackBy && localValue) {
          return true
        }
        return false
      }
      return true
    },
    triggerValidationCheck() {
      this.shouldValidate = true
      this.isValid = this.validate(this.localValue)
      this.$emit('valid', this.isValid)
      this.setValidState()
    },
    setValidState() {
      if (this.inputGroupParent && this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: !this.isValid })
      }
    },
    setOperation(overrideOperation) {
      let operationsToSet = []

      if (overrideOperation) {
        operationsToSet.push(overrideOperation)
      } else {
        let single = {},
          value = this.trackBy && this.localValue ? this.localValue[this.trackBy] : this.localValue,
          from = this.trackBy && this.oldValue ? this.oldValue[this.trackBy] : this.oldValue

        single.value = value
        single.type = this.operation
        single.from = from

        if (!value && this.operationEmpty) {
          single.type = this.operationEmpty
        }

        if (this.customFieldValueWrapper) {
          let copy = { ...this.customFieldValueWrapper }
          copy.value.value = value

          single.value = copy
        }
        operationsToSet.push(single)
      }
      operationsToSet.forEach(operation => {
        if (this.inputGroupParent && this.inputGroupParent.setOperation) {
          this.inputGroupParent.setOperation({
            operation,
            allowDuplicateOperationType: !!this.customFieldValueWrapper,
            operationTypes: { set: this.operation, clear: this.operationEmpty }
          })
        }
        if (this.hasChange) {
          this.$emit('operation', operation)

          if (this.clearAfterEmitOperation) {
            setTimeout(() => {
              this.resetValue()
            }, 1000)
          }
        }
      })
    },
    resetValue() {
      this.localValue = null
      this.oldValue = null
      this.valueForRadio = null
      this.$emit('update:modelValue', null)
      this.$emit('change', null)
    },
    removeOperation() {
      if (this.inputGroupParent && this.inputGroupParent.removeOperation) {
        this.inputGroupParent.removeOperation({ type: this.operation, operationTypes: { set: this.operation, clear: this.operationEmpty } })
      }
    },
    initButtonGroupWidth() {
      clearTimeout(this.initButtonGroupWidthTimeoutId)
      this.initButtonGroupWidthTimeoutId = setTimeout(() => {
        if (this.displayAsButtonGroup && this.$refs.buttonGroup) {
          this.$emit('buttonGroupWidth', this.$refs.buttonGroup.clientWidth + 2)
        }
      }, 200)
    }
  },
  mounted() {
    if (this.operationEmpty && this.required) {
      console.error(this.label, ` - Can't have a clear operation at the same time with a required flag: operationEmpty: ${this.operationEmpty}`)
    }

    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }
    if (this.inputGroupParent) {
      this.disabledLocal = !!this.inputGroupParent.disabled
      this.operationTriggerButtonLocal = !!this.inputGroupParent.operationTriggerButton
      this.highlightOnValueChangeLocal = !!this.inputGroupParent.highlightOnValueChangeButton
      this.setValidState()
    }
    this.disabledLocal = this.disabledLocal || this.disabled

    setTimeout(() => {
      this.initButtonGroupWidth()
      this.infoTooltipVisible = !!this.infoTooltipKey
    }, 0)
  },
  beforeUnmount() {
    clearTimeout(this.initButtonGroupWidthTimeoutId)
    this.deactivate()
  },
  watch: {
    displayAsButtonGroup(current, previous) {
      this.initButtonGroupWidth()
      if (previous) {
        setTimeout(() => {
          this.prepareValue()
        }, 0)
      }
    },
    items() {
      // TODO: when we create new option through select field, we use trackBy in some places and we pass modelValue as number or string which we need to call prepareValue
      // again after items have changed to set them. In some other places we don't use trackBy although it is set by default as 'id'
      // but we pass modelValue as object and calling prepareValue will overwrite the value we have set due to the way of implementation
      // we need to review and refactor this component
      // examples of confilicts in usecase:
      // - add new family in CategoryOperations.vue
      // - add new depletion reason in MovementEdit.vue
      if (typeof this.modelValue === 'string' || typeof this.modelValue === 'number') {
        this.prepareValue()
      }
    },
    computedItems: 'initButtonGroupWidth',
    modelValue: {
      handler() {
        if (this.highlightOnValueChangeComputed && this.initDone) {
          this.highlighted = true

          setTimeout(() => {
            this.highlighted = false
          }, 1000)
        }
        setTimeout(() => {
          this.prepareValue()
        }, 0)
      },
      immediate: true
    },
    localValue: {
      handler() {
        this.isValid = this.validate(this.localValue)
      },
      immediate: true,
      deep: true
    },
    isValid: {
      handler() {
        this.setValidState()
      },
      immediate: true
    },
    disabled() {
      this.disabledLocal = this.disabled
    },
    'inputGroupParent.disabled': {
      handler() {
        this.disabledLocal = this.inputGroupParent.disabled || this.disabled
      }
    },
  }
}
</script>

<style lang="scss">
.border-radius-right-none {
  .multiselect .multiselect__tags {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.border-end-none {
  .multiselect .multiselect__tags {
    border-right-width: 0px;
  }
}

.border-radius-left-none {
  .multiselect .multiselect__tags {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.border-start-none {
  .multiselect .multiselect__tags {
    border-left-width: 0px;
  }
}

.multiselect__spinner {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  &::before, &::after {
    border-top-color: var(--bs-primary);
  }
}
</style>

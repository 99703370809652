<template>
  <b-container fluid class="movement-item-add p-0" v-if="configDataLoaded">
    <wiskInputGroup :disabled="disabled" class="row w-100 mt-3" @errorCountChanged="setValidState" :key="key">
      <b-row>
        <b-col cols="12" md="5">
          <wiskInput class="mb-lg-0" infoTooltipKey="86f3aa00-7e83-4143-bd31-6f256807feea" autocompleteOpenAbove autofocus :modelValue="selectedItemTitle" :label="translations.txtGenericAddItem"
          :placeholder="translations.txtGenericTypeToSearch" @autocompleteInput="onAutocompleteInput" @autocompleteSelected="onAutocompleteSelected"
          :autocompleteMinChars="1" autocompleteDisplayKey="title" emitFast
          :autocompleteItems="autocompleteItems" required autocomplete @clear="onAutocompleteSelected(null)" :autocompleteItemExists="!!selectedItem" no-gutters fitChildren :fitChildrenCols="1">
            <b-button v-if="selectedItem?.id" size="sm" variant="link" class="mt-1" @click="openEdit">
              <icon class="text-primary" name="wisk-edit" />
            </b-button>
            <template v-slot:autocompleteItemTemplate="{ autocompleteItem }">
              <div class="fill-width-height">
                {{ addSuffix(autocompleteItem) }}
              </div>
            </template>
          </wiskInput>
        </b-col>
        <b-col v-if="selectedItem?.dataType === 'pos_item'" cols="8" md="4">
          <wiskInput :label="translations.txtGenericUnits" v-model="measurement.quantity" inputType="number" decimalsAsNeeded :decimals="4" :minDecimals="2" :disabled="disabled" required />
        </b-col>
        <template v-if="selectedItem?.dataType === 'subrecipe'">
          <b-col cols="12" :md="3" class="">
            <wiskSelect infoTooltipKey="efb98a5a-cea7-46c7-a5ec-a086bd6ba352" :multiselectOptions="multiselectOptionsServingSize" :label="translations.txtPOSItemsServingSize" inputClass="w-100"
              :modelValue="selectedServingSize" operation="serving_size" @change="onServingSizeInput" :items="availableServingSizes" fixedWrapper
              :addNewItem="addServingSizeConfig" :disabled="disabled" :customLabelOverride="customServingSizeLabel">
              <template v-slot:beforeList>
                <li class="multiselect__element" style="padding: 5px 10px;">
                  {{ translations.txtPOSItemsAvailalbeServingSizes }}
                </li>
              </template>
            </wiskSelect>
          </b-col>
          <b-col cols="12" :md="4">
            <measurement :modelValue="selectedServingSize.measurement" @update:modelValue="onServingSizeInput({ id: -1, measurement: $event, ...$event, title: 'Custom', type: 'custom' })"
              :disabled="(disabled || (selectedServingSize && selectedServingSize.id > 0))" v-if="selectedServingSize" horizontal inline :preferredType="measurementType"
              :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]"
              :label="translations.txtVenueBottlesMeasurement" :disableTypeChange="!!selectedItem" :multiselectOptionsMeasurements="{ openDirection: 'above' }"/>
          </b-col>
        </template>
        <b-col cols="12">
            <b-button :disabled="!formValid" size="sm" class="text-primary align-self-end float-end mb-sm-3 border-0 mt-auto" variant="link" @click="emitAddOperation" :title="translations.txtGenericAdd" ref="buttonAdd">
              <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
              <icon style="margin-top: -8px;" name="wisk-check" :scale="0.9" class="ms-1" />
            </b-button>
          </b-col>
      </b-row>
    </wiskInputGroup>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import { getStringForSearchRecursive, stringFilter, compareNumbers } from '@/modules/utils'
import measurement from '@/components/bottles/Measurement'

export default {
  name: 'MovementPOSItemSubrecipeAdd',
  emits: ['add'],
  components: { measurement },
  props: { movementId: Number, disabled: Boolean, },
  data() {
    return {
      autocompleteItems: [],
      key: 1,
      measurement: { quantity: 0, type: 'unit' },
      formValid: true,
      selectedItem: null,
      selectedItemTitle: '',
      selectedServingSize: null,
      multiselectOptionsServingSize: {
        showLabels: false,
        preselectFirst: false,
        allowEmpty: false,
        searchable: true,
        maxHeight: 350,
        openDirection: 'above'
      }
    }
  },
  computed: {
    ...mapState(['translations', 'posItems', 'venue', 'subrecipes', 'servingSizes']),
    ...mapGetters(['configDataLoaded']),
    configs() {
      return {
        subrecipe: { idKey: 'subrecipe_id' , action: 'subrecipeEdit', title: this.translations.txtSubrecipeTitle, operation: 'subrecipe_add' },
        pos_item: { idKey: 'pos_item_id' , action: 'posItemEdit', title: this.translations.txtGenericPOSItem, operation: 'pos_item_add' }
      }
    },
    searchData() {
      return [...this.posItems.filter(p => p && !p.archived).map(p => ({ ...p, dataType: 'pos_item', search_field: getStringForSearchRecursive({ payload: p, stopAtLevel: 1 }) })),
        ...this.subrecipes.filter(s => s && !s.archived)
          .map(s => ({ ...s, dataType: 'subrecipe', search_field: getStringForSearchRecursive({ payload: s, stopAtLevel: 1 }), title: `${s.title} (${s.yields.measurement.quantity + s.yields.measurement.unit_of_measurement})` }))]
    },
    availableServingSizes() {
      let servingSizes = this.servingSizes
        .filter(s => !s.archived)
        .map(s => merge({}, s))
        .sort((a, b) => compareNumbers(a.sort_order, b.sort_order))

      if (this.measurementType) {
        servingSizes = servingSizes.filter(s => s && s.measurement && (s.measurement.type === this.measurementType || s.measurement.type === 'unit'))
      }
      return servingSizes
    },
    addServingSizeConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'servingSizeEdit',
            action: {
              id: 0,
              title: searchQuery,
              measurement: { type: this.measurementType, unit_of_measurement: 'g' },
              onChange: servingSize => {
                callbackItemInjected(servingSize)
              }
            }
          })
        },
        label: this.translations.txtServingSizesAddServingSize
      }
    },
    measurementType() {
      if (this.selectedItem && this.selectedItem.measurement) {
        return this.selectedItem.measurement.type
      }
      return null
    },
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.formValid = !errorCount
    },
    addSuffix(item) {
      return (item && `${item.title} - ${this.configs[item.dataType]?.title}`) || ''
    },
    openEdit() {
      this.setGlobalAction({ type: this.configs[this.selectedItem.dataType]?.action, action: { id: this.selectedItem.id } })
    },
    onAutocompleteSelected(item) {
      const temp = merge({}, item)
      if (temp.dataType === 'subrecipe' && temp.yields) {
        temp.measurement = temp.yields.measurement
      }
      this.selectedItem = temp
      this.selectedItemTitle = this.addSuffix(item)
    },
    onAutocompleteInput(value) {
      this.autocompleteItems = this.searchData.filter(b => stringFilter('contains', b.search_field, value))
    },
    emitAddOperation() {
      let operation = {
        [this.configs[this.selectedItem.dataType]?.idKey]: this.selectedItem.id,
        sort_order: new Date().getTime()
      }
      if (this.selectedServingSize) {
        operation.measurement = {
          ...this.selectedServingSize.measurement,
        }
        operation.measurement.type = 'manual'
      } else {
        operation.measurement = this.measurement
      }

      this.$emit('add', { type: this.configs[this.selectedItem.dataType]?.operation, value: operation })

      this.measurement = { quantity: 0, type: 'unit' }
      this.selectedItem = null
      this.selectedItemTitle = ''

      setTimeout(() => {
        this.key++
      }, 100)
    },
    customServingSizeLabel(item) {
      return item.type === 'custom' ? item.title : `${item.title} (${item.measurement.quantity} ${item.measurement.unit_of_measurement})`
    },
    onServingSizeInput(value) {
      if (value) {
        this.selectedServingSize = { ...value }
      }
    }
  },
  created() {},
  watch: {}
}
</script>

<style lang="scss">
</style>

<template>
  <div style="display: block; position: absolute; z-index: 999; bottom: 0;" :style="{ [position]: position === 'right' ? '5px' : '25px' }">
    <b-button v-for="(button, index) in buttons" size="sm" :variant="button.icon ? 'link' : 'primary'" class="info-icon" @click.stop="button.action(id, data)"
      :style="{ right: index ? (31 * index) + 'px' : null, opacity: forceInfoComponent ? 1 : null }" :key="button.id" v-tooltip="button.title || ''">
      <icon v-if="button.icon" class="text-primary" :name="button.icon" :scale="button.iconScale" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ExtraButtons',
  props: { params: Object, extraButtons: Array },
  computed: {
    id() {
      return this.params.value.id
    },
    buttons() {
      let buttons = this.extraButtons || this.params?.extraButtons || this.params?.value?.extraButtons

      if (Array.isArray(buttons)) {
        return buttons.filter(b => (!b.getVisible || (b.getVisible && b.getVisible(this.params.data))) && b.icon) || []
      }

      return []
    },
    data() {
      return this.params.value
    },
    forceInfoComponent() {
      return !!this.params.forceInfoComponent || (window.CobrowseIO && window.CobrowseIO.currentSession)
    },
    position() {
      if (this.params.extraButtonsPosition) {
        return this.params.extraButtonsPosition
      }
      return 'right'
    }
  }
}
</script>

<template>
  <div style="display: block; position: absolute; z-index: 9; bottom: 0px; opacity: .6; font-size: 90%; text-wrap: auto; line-height: 1;"
    :style="{ [position]: positionPadding }">
    {{ extraText }}
    <v-tooltip v-if="tooltip" class="d-inline-block">
      <icon class="text-primary" name="wisk-information-button" :scale=".7"></icon>

      <template #popper>
        <div style="width: 200px;">
          {{ tooltip }}
        </div>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ExtraText',
  props: { params: Object, text: String },
  data() {
    return {
      localText: ''
    }
  },
  computed: {
    id() {
      return this.params.value.id
    },
    extraText() {
      return this.localText || this.text || this.params.extraText || this.params.value.extraText || ''
    },
    position() {
      if (this.params.extraTextPosition) {
        return this.params.extraTextPosition
      }
      return 'left'
    },
    positionPadding() {
      return this.params.extraTextPositionPadding || '5px'
    },
    tooltip() {
      return this.params.value.tooltip
    }
  },
  mounted() {
    if (this.params.getExtraText) {
      this.localText = this.params.getExtraText(this.params)
    }
  },
}
</script>

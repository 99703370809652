<template>
  <div>
    <template v-if="data">
      <div class="mb-4">
        <highcharts :options="masterChartOptions" ref="masterChart" />
      </div>
      <div class="mb-4">
        <highcharts :options="costAlertOptions" ref="costAlertChart" />
      </div>
      <div class="mb-4">
        <highcharts :options="recipeCostOptions" ref="recipeCostChart" />
      </div>
      <div v-if="hasSubrecipe">
        <highcharts :options="ingredientCostOptions" ref="ingredientChart" />
      </div>
    </template>
    <wiskLoading loading v-else/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import { currencyFormat, formatDate, percentageFormat, toFixed } from '@/modules/utils.js'
import api from '@/api/index'

export default {
  name: 'POSItemHistory',
  components: { },
  props: {
    posItem: Object,
  },
  data() {
    return {
      data: null,
      selectedData: null,
      item: null,
      dateRange: {
        min: null,
        max: null
      }
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['translations', 'allergens', 'subrecipesById', 'currentPermissionsByType']),
    hasSubrecipe() {
      return this.posItem.ingredients.find(ingredient => ingredient.subrecipe_id)
    },
    masterChartOptions() {
      if (!this.data?.history?.length) return {}

      const dates = this.data.history.map(item => DateTime.fromJSDate(new Date(item.business_day)).toMillis()),
        tempThis = this

      return {
        title: {
          text: undefined
        },
        chart: {
          height: 90,
          reflow: false,
          marginLeft: 50,
          marginRight: 30,
          type: 'spline',
        },
        rangeSelector: {
          enabled: true,
          buttons: [{
            type: 'week',
            count: 1,
            text: '1 week'
          }, {
            type: 'month',
            count: 1,
            text: '1 month'
          }, {
            type: 'month',
            count: 3,
            text: '3 month'
          }, {
            type: 'all',
            text: 'All'
          }],
          selected: 3,
          inputEnabled: false,
          floating: false,
          y: 0,
          buttonTheme: {
            width: 60
          }
        },
        navigator: {
          enabled: true,
          height: 30,
          margin: 30,
          xAxis: {
            labels: {
              enabled: true,
              format: '{value:%b %e}',
              rotation: -45,
              step: 2,
              style: {
                fontSize: '10px'
              },
              y: 1,
              distance: -5
            },
            gridLineWidth: 0,
            tickWidth: 0,
            tickPositions: dates,
            breaks: [{
              breakSize: 24 * 3600 * 1000,
              repeat: 24 * 3600 * 1000
            }]
          },
          series: {
            type: 'area',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, '#7cb5ec'],
                [1, 'rgba(124,181,236,0)']
              ]
            }
          }
        },
        xAxis: {
          type: 'datetime',
          labels: {
            enabled: false
          },
          tickPositions: dates,
          events: {
            afterSetExtremes(e) {
              tempThis.dateRange = {
                min: e.min,
                max: e.max
              }
            }
          },
          visible: false
        },
        yAxis: {
          visible: false
        },
        series: [{
          data: dates.map(date => [date, 1]),
          visible: true,
          marker: { enabled: false },
          enableMouseTracking: false,
          showInLegend: false,
          opacity: 0
        }],
        credits: { enabled: false },
        legend: { enabled: false },
        scrollbar: {
          enabled: false
        }
      }
    },
    costAlertOptions() {
      const dates = []
      const currentValues = []
      const targetValues = []
      const notificationPoints = []

      this.data.history.filter(h => this.ifInRange(h)).forEach(item => {
        const businessDay = item.business_day
        const ms = DateTime.fromJSDate(new Date(businessDay)).toMillis()
        const currentValue = item.data.cost_alert.current_value * 100
        const targetValue = item.data.cost_alert.target_value ? item.data.cost_alert.target_value * 100 : null
        const shouldAlert = item.data.cost_alert.should_alert

        dates.push(ms)
        currentValues.push({ x: ms, y: currentValue, extra: {
          cost: item.data.cost_alert.cost,
          price: item.data.cost_alert.price,
          profit: item.data.recipe_cost.stats.cost.profit
        } })
        targetValues.push(targetValue !== null ? { x: ms, y: targetValue } : { x: null, y: null })

        if (shouldAlert) {
          notificationPoints.push({
            x: ms,
            y: currentValue,
            title: this.translations.txtPurchaseOrdersEmailSentText
          })
        }
      })
      const translations = this.translations

      return {
        title: {
          text: this.translations.txtPOSItemsCostPercentageHistory
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%b %e}',
            rotation: -45,
            step: 2
          },
          tickPositions: dates
        },
        yAxis: {
          title: {
            text: this.translations.txtGenericCostPercentage + ' (%)'
          },
          labels: {
            format: '{value:.1f}%'
          }
        },
        tooltip: {
          formatter() {
            const date = DateTime.fromMillis(this.x).toFormat('MMMM d, yyyy')
            let s = `<span style="font-size: 10px">${date}</span><br/>`

            const pointData = this.points[0].point
            const extra = pointData.extra

            this.points.forEach(point => {
              s += `<span style="color:${point.series.color}"><b>${point.series.name}</b>: ${toFixed(point.y, 1)}%</span><br/>`
            })

            s += '<div style="margin: 5px 0; border-bottom: 1px solid gray" />'
            s += `<span><b>${translations.txtGenericCost}</b>: ${currencyFormat(extra.cost)}</span><br/>`
            s += `<span><b>${translations.txtGenericPrice}</b>: ${currencyFormat(extra.price)}</span><br/>`
            s += `<span><b>${translations.txtGenericProfit}</b>: ${currencyFormat(extra.profit)}</span><br/>`

            return s
          },
          shared: true
        },
        series: [
          {
            name: this.translations.txtGenericCostPercentage,
            data: currentValues,
          },
          {
            name: this.translations.txtPOSItemsCostPercentageHistoryTargetValue,
            data: targetValues,
            dashStyle: 'Dash'
          },
          {
            type: 'flags',
            name: this.translations.txtPOSItemsCostPercentageHistoryNotification,
            data: notificationPoints,
            shape: 'squarepin',
            enableMouseTracking: false
          }
        ],
        credits: {
          enabled: false
        }
      }
    },
    recipeCostOptions() {
      const chartData = this.data.history
      const seriesData = {}
      const dates = []

      // Process the data
      chartData.filter(h => this.ifInRange(h)).forEach((entry) => {
        const ms = DateTime.fromJSDate(new Date(entry.business_day)).toMillis()
        dates.push(ms)

        // Get ingredients from the entry
        const ingredients = entry.data.recipe_cost.ingredients || []
        ingredients.forEach((ingredient) => {
          if (!ingredient.title || !ingredient.stats?.cost?.dollars) return

          if (!seriesData[ingredient.title]) {
            seriesData[ingredient.title] = []
          }

          seriesData[ingredient.title].push({
            x: ms,
            y: ingredient.stats.cost.dollars,
            extra: ingredient
          })
        })
      })

      // Convert to series array
      const series = Object.keys(seriesData).map(title => ({
        name: title,
        data: seriesData[title],
        type: 'area'
      }))

      return {
        chart: {
          type: 'area',
          height: 400
        },
        title: {
          text: this.translations.txtPOSItemRecipeCostIngredients
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%b %e}',
            rotation: -45,
            step: 2
          },
          tickPositions: dates
        },
        yAxis: {
          labels: {
            format: '${text}'
          },
          title: {
            text: this.translations.txtPOSItemIngredientCost
          },
          stackLabels: {
            enabled: true,
            format: '${total:.2f}'
          }
        },
        tooltip: {
          shared: true,
          valuePrefix: '$',
          valueDecimals: 2,
          headerFormat: '<span style="font-size: 10px">{point.key:%B %e, %Y}</span><br/>',
          formatter() {
            const date = DateTime.fromMillis(this.x).toFormat('MMMM d, yyyy')
            let s = `<span style="font-size: 10px">${date}</span><br/>`

            const currentSeries = this.points.find(p => p.series.state === 'hover')?.series

            this.points.forEach(point => {
              const isCurrent = currentSeries && point.series === currentSeries
              const style = isCurrent ? 'font-weight: bold;' : 'font-weight: normal;'
              const opacity = isCurrent ? 1 : 0.6
              const measurement = point.point.extra.measurement
              s += `<span style="color:${point.series.color};${style};opacity:${opacity}">${point.series.name}</span>: $${toFixed(point.y, 2)} (${measurement.quantity} ${measurement.unit_of_measurement})<br/>`
            })

            return s
          }
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666',
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                lineWidth: 3,
                brightness: 0.1
              },
              inactive: {
                opacity: 0.3
              }
            },
            stickyTracking: false,
            trackByArea: true,
            events: {
              mouseOver() {
                const series = this.chart.series
                series.forEach(s => {
                  if (s !== this) {
                    s.setState('inactive')
                  } else {
                    s.setState('hover')
                  }
                })

                const x = this.x
                const chart = this.chart
                const plotLines = chart.xAxis[0].plotLinesAndBands || []
                plotLines.forEach(line => line.destroy())
                chart.xAxis[0].addPlotLine({
                  value: x,
                  color: 'red',
                  width: 1,
                  zIndex: 5
                })
              },
              mouseOut() {
                const series = this.chart.series
                series.forEach(s => {
                  s.setState('')
                })

                const plotLines = this.chart.xAxis[0].plotLinesAndBands || []
                plotLines.forEach(line => line.destroy())
              }
            }
          }
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          borderWidth: 0
        },
        credits: {
          enabled: false
        },
        series
      }
    },
    ingredientCostOptions() {
      const chartData = this.data.history
      const seriesData = {}
      const dates = []

      // Process the data
      chartData.filter(h => this.ifInRange(h)).forEach((entry) => {
        const ms = DateTime.fromJSDate(new Date(entry.business_day)).toMillis()
        dates.push(ms)

        // Get depleted items from the entry
        const items = entry.data.recipe_cost.depleted_items || []
        items.forEach((item) => {
          if (!item.title || !item.stats?.cost?.dollars) return

          if (!seriesData[item.title]) {
            seriesData[item.title] = []
          }

          // Find existing data point for this date or create new one
          const existingPoint = seriesData[item.title].find(p => p.x === ms)
          if (existingPoint) {
            existingPoint.y += item.stats.cost.dollars
            existingPoint.extra.measurements += `, ${toFixed(item.stats.cost.measurement.quantity, 2)} ${item.stats.cost.measurement.unit_of_measurement}`
          } else {
            seriesData[item.title].push({
              x: ms,
              y: item.stats.cost.dollars,
              extra: { measurements: `${toFixed(item.stats.cost.measurement.quantity, 2)} ${item.stats.cost.measurement.unit_of_measurement}` }
            })
          }
        })
      })

      // Convert to series array
      const series = Object.keys(seriesData).map(title => ({
        name: title,
        data: seriesData[title],
        type: 'area'
      }))

      return {
        chart: {
          type: 'area',
          height: 400
        },
        title: {
          text: this.translations.txtPOSItemIngredientCostBreakdown
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%b %e}',
            rotation: -45,
            step: 2
          },
          tickPositions: dates
        },
        yAxis: {
          labels: {
            format: '${text}'
          },
          title: {
            text: this.translations.txtPOSItemsCostPercentageHistoryCostPerIngredient
          },
          stackLabels: {
            enabled: true,
            format: '${total:.2f}'
          }
        },
        tooltip: {
          shared: true,
          valuePrefix: '$',
          valueDecimals: 2,
          headerFormat: '<span style="font-size: 10px">{point.key:%B %e, %Y}</span><br/>',
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: ${point.y:.2f} ({point.extra.measurements})<br/>'
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666',
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        series
      }
    }
  },
  methods: {
    currencyFormat,
    percentageFormat,
    formatDate,
    ifInRange(h) {
      const ms = DateTime.fromJSDate(new Date(h.business_day)).toMillis()
      if (this.dateRange.min && this.dateRange.max) {
        return ms >= this.dateRange.min && ms <= this.dateRange.max
      }
      return true
    }
  },
  mounted() {
    api.getPOSItemHistory(this.posItem.id).then(response => {
      this.data = response
    })
  }
}
</script>

<style scoped lang="scss">

</style>

import get from 'lodash.get'
import { DateTime } from 'luxon'
import { formatDate, round, currencyFormatHideZero, toFixedHideZero } from '@/modules/utils'
import agGridFilters from '@/modules/agGridFilters'
import cellRenderers from '@/components/cells/cellRenderers'

const gridOptions = {}

const getGridColumns = ({ translations, setGlobalAction }) => [
  {
    headerName: translations.txtGenericYear,
    colId: 'year',
    wiskExpandFirst: true,
    sortOrder: 1800,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value, id: params.value }),
    valueGetter: params => (params.data && params.data.date && params.data.date.getFullYear && params.data.date.getFullYear()) || ''
  },
  {
    headerName: translations.txtGenericMonth,
    colId: 'month',
    wiskExpandFirst: true,
    sortOrder: 1900,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify(params.value),
    valueFormatter: params => params.value.title,
    valueGetter: params => {
      let month = (params.data && params.data.date && params.data.date.getMonth && params.data.date.getMonth()) || 0

      return {
        colId: 'month',
        sort: month + 1,
        title: translations[`txtGenericMonth${month}`],
        id: params.data && params.data.date && { month, year: params.data.date.getFullYear() }
      }
    }
  },
  {
    headerName: translations.txtGenericWeek,
    colId: 'week',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    enableRowGroup: true,
    hide: true,
    wiskExpandFirst: true,
    sortOrder: 2000,
    keyCreator: params => JSON.stringify(params.value),
    valueFormatter: params => params.value.title,
    valueGetter: params => {
      let dt = (params.data && params.data.date && DateTime.fromJSDate(params.data.date)) || null,
        day = (dt && dt.dayNumber) || 0,
        week = (dt && dt.weekNumber) || '-',
        month = (dt && dt.monthNumber) || 0,
        year = (dt && dt.year) || '-',
        sort = parseInt(year.toString() + month.toString() + day.toString(), 10),
        title = (dt && `${translations.txtGenericWeek} ${week}, ${translations.txtGenericStartedOn} ${dt.startOf('week').toFormat('DD')}`) || ''

      return { colId: 'week', sort, title }
    }
  },
  {
    headerName: translations.txtGenericId,
    colId: 'id',
    hide: true,
    sortOrder: 2200,
    ...agGridFilters.text,
    field: 'id'
  },
  {
    headerName: translations.txtGenericDate,
    colId: 'date',
    field: 'date',
    sort: 'desc',
    sortOrder: 2300,
    ...agGridFilters.date,
    valueFormatter: params => formatDate(params.value)
  },
  {
    headerName: translations.txtGenericPartnerVenue,
    colId: 'partnerVenue',
    sortOrder: 2400,
    valueGetter: params => get(params, 'data.partner_venue.title', '')
  },
  {
    colId: 'createdBy',
    headerName: translations.txtGenericCreatedBy,
    minWidth: 140,
    width: 170,
    sortOrder: 2500,
    cellClass: [],
    enableRowGroup: true,
    hide: true,
    valueGetter: params => params.data && params.data.user && params.data.user.name
  },
  {
    colId: 'status',
    headerName: translations.txtGenericStatus,
    cellRenderer: 'CellText',
    minWidth: 70,
    maxWidth: 140,
    cellClass: ['text-center', 'wrap'],
    ...agGridFilters.text,
    sortOrder: 2600,
    enableRowGroup: true,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      input_value: (params.data && translations.groupTransferRequestStatuses[get(params, 'data.status.type')]) || ''
    })
  },
  {
    colId: 'totalItems',
    headerName: translations.txtScannedInvoicesTotalItems,
    cellRendererParams: { useValueFormatter: true, decimals: 2 },
    sortOrder: 2700,
    cellClass: ['text-end'],
    ...agGridFilters.number,
    valueFormatter: params => toFixedHideZero(params.value),
    valueGetter: params => get(params, 'data.stats.units')
  },
  {
    colId: 'total',
    headerName: translations.txtGenericTotal,
    cellRendererParams: { decimals: 2 },
    sortOrder: 2800,
    cellClass: ['text-end', 'currency'],
    ...agGridFilters.number,
    valueFormatter: params => currencyFormatHideZero(params.value),
    valueGetter: params => get(params, 'data.stats.dollars')
  },
  {
    colId: 'more',
    sortOrder: 27000,
    headerName: translations.columnDetails,
    minWidth: 100,
    width: 100,
    cellClass: ['cell-more-details'],
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      excludeFromExport: true,
      onClick: id => {
        setGlobalAction({ type: 'venueTransferEdit', action: { id } })
      }
    },
    valueGetter: params => ({
      id: (params.data && params.data.id) || '',
      group: !!params.node.group
    })
  }
]

const getItemsGridColumns = ({ translations, venue, updateValue, disabled, openMeasurement, activeTaxes, taxesById, setGlobalAction, openPriceEditor, currentPermissionsByType }) => ({
  dropdownMenu: {
    forceOverrideValuegetter: true,
    remove: disabled,
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: item => {
            updateValue({ type: 'item_delete', value: item.item_distributor_id })
          },
          disabled,
          label: translations.txtGenericRemove,
          value: params.data
        }
      ]
    })
  },
  image: {
    hide: false
  },
  title: {
    hide: false,
    sort: null
  },
  distributorCode: { hide: false },
  distributor: { hide: true },
  family: { hide: true },
  category: { hide: true },
  itemMeasurement: {
    colId: 'itemMeasurement',
    sortOrder: 400,
    headerName: translations.txtMovementsItemMeasurement,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    cellClass: ['text-end'],
    minWidth: 100,
    maxWidth: 150,
    sort: 'asc',
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      infoComponentType: 'extraText'
    },
    valueGetter: params => {
      let measurement = get(params, 'data.venueTransferInfo.item_distributor_measurement', get(params, 'data.venueTransferInfo.item_measurement')),
        caseSize = (measurement && measurement.case_size) || (params.data && params.data.case_size) || 1,
        formatted = `${get(measurement, 'quantity')} ${get(measurement, 'unit_of_measurement', get(measurement, 'type'))}`

      return {
        id: params.data && params.data.item_distributor_id,
        input_value: formatted,
        item: params.data,
        extraText: measurement && measurement.type === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
      }
    }
  },
  lineMeasurement: {
    colId: 'lineMeasurement',
    sortOrder: 410,
    headerName: translations.txtMovementsLineMeasurement,
    cellRenderer: 'CellText',
    ...agGridFilters.text,
    cellClass: ['text-end'],
    minWidth: 100,
    maxWidth: 200,
    sort: 'asc',
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      extraButtons: [
        {
          id: 1,
          action: openMeasurement,
          icon: 'wisk-edit',
          getVisible: () => !disabled
        }
      ],
      infoComponentType: 'extraButtons',
      secondaryInfoComponentType: 'extraText'
    },
    valueGetter: params => {
      let measurement = get(params, 'data.venueTransferInfo.measurement', {}),
        caseSize = (measurement && measurement.case_size) || (params.data && params.data.case_size) || 1,
        formatted = `${get(measurement, 'quantity')} ${get(measurement, 'unit_of_measurement', get(measurement, 'type'))}`

      return {
        id: params.data && params.data.item_distributor_id,
        input_value: formatted,
        item: params.data,
        extraText: measurement.type === 'case' && caseSize > 1 ? `${translations.txtGenericCaseSize}: ${caseSize}`.toLowerCase() : ''
      }
    }
  },
  priceInMovement: {
    colId: 'priceInMovement',
    hide: false,
    sortOrder: 1920,
    headerName: translations.txtGenericCost,
    cellRenderer: 'CellText',
    minWidth: 100,
    maxWidth: 200,
    cellClass: ['text-center'],
    cellRendererParams: {
      readonly: true,
      translate: translations.translate,
      extraButtons: [
        {
          id: 1,
          action: openPriceEditor,
          icon: 'wisk-edit',
          getVisible: () => !disabled
        }
      ],
      infoComponentType: 'extraButtons',
      secondaryInfoComponentType: 'extraText'
    },
    valueGetter: params => {
      let type = get(params, 'data.venueTransferInfo.price.type', 'unit')

      if (type === 'manual') {
        type = `${get(params, 'data.venueTransferInfo.price.measurement.quantity')} ${get(params, 'data.venueTransferInfo.price.measurement.unit_of_measurement')}`
      }
      let formatted = `${window.WiskGlobals.currency}${round(get(params, 'data.venueTransferInfo.price.value', 0), 2) || '-'} / ${type}`,
        discount = get(params, 'data.venueTransferInfo.price.discount')

      return {
        id: params.data && params.data.item_distributor_id,
        input_value: formatted,
        data: params.data && params.data.venueTransferInfo,
        extraText: discount ? `${translations.txtGenericDiscount}: ${window.WiskGlobals.currency}${discount}` : ''
      }
    }
  },
  retailPrice: {
    hide: true,
    sortOrder: 1921
  },
  tax: {
    colId: 'tax',
    sortOrder: 2100,
    headerName: translations.txtTax,
    enableRowGroup: true,
    cellEditor: 'CellPopMultiselect',
    cellRenderer: cellRenderers.CellPopMultiselect,
    editable: () => currentPermissionsByType.tax_rate_assign && !disabled,
    remove: !venue.taxes_enabled,
    valueSetter: params => {
      params.data.venueTransferInfo.tax_rate_id = params.newValue
      return true
    },
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      infoComponentType: 'extraText',
      extraTextPositionPadding: '11px',
      forceInfoComponent: true,
      readonly: !currentPermissionsByType.tax_rate_assign || disabled,
      updateValue: params => {
        console.log('params.data', params.data)
        let value = { ...params.data.data },
          from = { ...params.data.data }

        updateValue({ ...params, value, from })
      },
      type: 'item_update',
      trackBy: 'id',
      getItems: () => activeTaxes,
      addNewItem: {
        action: (id, searchQuery, value, callbackRefresh) => {
          setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackRefresh(tax)
              }
            }
          })
        },
        label: translations.txtTaxesNew
      }
    },
    ...agGridFilters.text,
    valueGetter: params => ({
      id: params.data && params.data.item_id,
      input_value: get(taxesById, get(params, 'data.venueTransferInfo.tax_rate_id'), { title: '-' }),
      data: params.data && params.data.venueTransferInfo,
      extraText: currencyFormatHideZero(get(params, 'data.venueTransferInfo.total', 0) - get(params, 'data.venueTransferInfo.subtotal', 0))
    })
  },
  totalPerLine: {
    colId: 'totalPerLine',
    cellRenderer: 'CellNumber',
    cellClass: ['text-end', 'pt-2', 'pe-2'],
    minWidth: 100,
    maxWidth: 200,
    pinned: null,
    aggFunc: 'wiskSum',
    sortOrder: 2700,
    headerName: translations.txtInventoriesTotalBottles,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      decimals: 2
    },
    valueGetter: params => ({
      id: params.data && params.data.item_distributor_id,
      input_value: get(params, 'data.venueTransferInfo.stats.units', 0)
    })
  },
  totalDollarsPerLine: {
    colId: 'totalDollarsPerLine',
    cellRenderer: 'CellNumber',
    cellClass: ['text-end', 'pt-2', 'pe-2', 'currency'],
    minWidth: 100,
    maxWidth: 200,
    pinned: null,
    sortOrder: 2700,
    aggFunc: 'wiskSum',
    headerName: translations.txtDashboardTotalValue,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      decimals: 2,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      id: params.data && params.data.item_distributor_id,
      input_value: get(params, 'data.venueTransferInfo.stats.dollars', 0)
    })
  }
})

const getTransfersReportColumns = ({ translations, setGlobalAction }) => ({
  title: {},
  itemVariant: {
    colId: 'itemVariant',
    sortOrder: 1800,
    headerName: translations.txtVenueBottlesVariant,
    ...agGridFilters.text,
    minWidth: 190,
    maxWidth: 400,
    hide: true,
    suppressColumnsToolPanel: true,
    cellRenderer: 'CellText',
    enableRowGroup: true,
    keyCreator: params => get(params, 'value.parent', '') + ' - ' + get(params, 'value.input_value', ''),
    cellRendererParams: { translate: translations.translate, readonly: true },
    valueGetter: params => ({
      id: get(params, 'data.variant.id', ''),
      parent: get(params, 'data.title', ''),
      input_value: get(params, 'data.variant.title', '')
    })
  },
  prtnerVenue: {
    colId: 'prtnerVenue',
    sortOrder: 1900,
    headerName: translations.txtGenericPartnerVenue,
    ...agGridFilters.text,
    minWidth: 190,
    maxWidth: 400,
    enableRowGroup: true,
    valueGetter: params => get(params, 'data.venueTransferInfo.venueTransfer.partner_venue.title', '')
  },
  venueTransferId: {
    colId: 'venueTransferId',
    sortOrder: 2000,
    cellClass: ['text-end'],
    cellRenderer: 'CellText',
    headerName: translations.txtVenueTransferRequestId,
    ...agGridFilters.number,
    minWidth: 100,
    maxWidth: 300,
    keyCreator: params => get(params, 'value.input_value', ''),
    cellRendererParams: { translate: translations.translate, readonly: true },
    enableRowGroup: true,
    valueGetter: params => ({
      id: get(params, 'data.combined_id', ''),
      input_value: get(params, 'data.venueTransferInfo.transfer_request_id', '')
    })
  },
  quantity: {
    colId: 'quantity',
    sortOrder: 2100,
    headerName: translations.columnQuantityUnits,
    cellRenderer: 'CellNumber',
    minWidth: 100,
    width: 100,
    aggFunc: 'wiskSum',
    cellRendererParams: { translate: translations.translate, decimals: 2, readonly: true },
    valueGetter: params => ({
      id: get(params, 'data.combined_id'),
      input_value: get(params, 'data.venueTransferInfo.units', 0)
    })
  },
  more: {
    cellRendererParams: {
      onClick: (id, cellData) => {
        setGlobalAction({ type: 'venueTransferEdit', action: { id: cellData.data.venueTransferInfo.transfer_request_id } })
      }
    }
  }
})

export { getGridColumns, gridOptions, getItemsGridColumns, getTransfersReportColumns }
